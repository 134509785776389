import React from "react";
import CareerApply from "../components/CareerApply/CareerApply";
import CareerCard from "../components/CareerCard/CareerCard";
import Footerr from "../components/Footerr/Footerr";
import { Header } from "../components/shared";

function CareerPage() {
  return (
    <div>
      <Header />
      <CareerApply />
      <CareerCard />
      <div className="relative">
        <Footerr />
      </div>
    </div>
  );
}

export default CareerPage;
