import React, { useState } from "react";
import Swal from "sweetalert2";
import logo from "./../shared/Header/media/logo-white-main.png";
import { Link } from "react-router-dom";

import { FaFacebookF, FaYoutube, FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { BiRightArrow } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Thankyou from "./media/Thank you2.png";
import hand from "./media/righthand.gif";

import "./Footerr.css";

const Footerr = () => {
  const [input, setInput] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (input !== "") {
      Swal.fire({
        title: "Thanks for subscribing!",
        text: "You will be the first to know about our newsletter.",
        imageUrl: Thankyou,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "welcome image",
      });
      setInput("");
    }
  };
  return (
    <>
      {" "}
      <div className="py-8 footer-box">
        <div className="upper grid grid-cols-1  lg:grid-cols-2 gap-4 items-center pb-10 border-b">
          <div className="">
            <h2 className="font-semibold text-2xl heading mb-2 text-white ">
              Embrace digital transformation with Terces!
            </h2>
            <p className="text-[#323232a1] paragraph-white text-[15px] !text-white">
              Revolutionize your business with our passion for innovative
              solutions and exceptional value. Join the movement.
            </p>
          </div>
          <div className=" border-none lg:border-l-2 border-gray-200 pl-0 lg:pl-52 ">
            <form
              onSubmit={handleSubmit}
              className="space-x-4 space-y-4 flex place-items-baseline"
              action="#"
            >
              <input
                required
                onChange={(e) => setInput(e.target.value)}
                value={input}
                type="email"
                className="bg-white focus:outline-none focus:ring-2  shadow-2xl rounded subscribe-input"
                placeholder="EMAIL"
              />

              <button type="submit" className="subscribe-btn">
                Subscribe
                <BiRightArrow className="text-white mt-[2px]" />
              </button>
            </form>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-8 pt-10">
          <div className="space-y-5">
            <div>
              <img src={logo} alt="" className="footer-logo" />
            </div>
            <p className="text-gray-500 md:w-[150%]  !text-white paragraph-white font-[Inter] text-[15px] ">
              At Terces Solutions, we're passionate about transforming business
              operations and driving success in a fast-evolving tech landscape
              through innovation and value delivery.
            </p>
          </div>
          <div class="footer-links-section">
            <h2 className="text-2xl font-bold mb-2 md:mt-[10px] text-white font-['Open_sans']">
              Quick Links
            </h2>
            <ul className="text-white font-[Inter] flex flex-col">
              <Link to="/Solutions" className="timeline-item-content">
                <li className="footer_links text-underline">Solutions</li>
              </Link>
              <Link to="/Services" className="timeline-item-content">
                <li className="footer_links text-underline">Services</li>
              </Link>
              <Link to="/Ourstory" className="timeline-item-content">
                <li className="footer_links text-underline">Our Story</li>
              </Link>
              <Link to="/Team" className="timeline-item-content">
                <li className="footer_links text-underline">Terces Team</li>
              </Link>
            </ul>
          </div>
          <div class="footer-links-section-2">
            <h2 className="text-2xl font-bold mb-2 md:mt-[10px] text-white font-['Open_sans']">
              Connect
            </h2>
            <ul className="text-white flex flex-col">
              <Link to="/Blogs" className="timeline-item-content">
                <li className="footer_links text-underline">Blogs</li>
              </Link>
              <Link to="/Careers" className="timeline-item-content">
                <li className="footer_links text-underline">Careers</li>
              </Link>
              <Link to="/Contact" className="timeline-item-content">
                <li className="footer_links text-underline">Contact</li>
              </Link>
              <Link to="/Contact" className="timeline-item-content">
                <li className="footer_links text-underline">Get Started</li>
              </Link>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-2 md:mt-[10px] text-white font-['Open_sans']">
              Address
            </h2>
            <ul className="text-white font-['Open_Sans'] text-[15px] text-[#ffffffdb] leading-[28px]">
              <li className="">
                2-5- 538/D, 4th Floor, <br />
                Dr. KSR Sastry Business Park, <br />
                KU-X Road Hanamkonda, TS 506009 India
              </li>
              <li className="">
                <a href="tel: +919581430505" id="call-terces">
                  <span className="inline-block">
                    <img src={hand} alt="" className="blog-hand1" />
                  </span>
                  +91 9581430505
                </a>
                <ReactTooltip
                  anchorId="call-terces"
                  place="left"
                  content="Talk to an expert"
                  className="footer-tooltip"
                />
              </li>
              <li className="">
                <a href="mailto: info@terces.io" id="mail-terces">
                  <span className="inline-block">
                    <img src={hand} alt="" className="blog-hand1" />
                  </span>
                  info@terces.io{" "}
                </a>
                <ReactTooltip
                  anchorId="mail-terces"
                  place="left"
                  content="Write to us"
                  className="footer-tooltip"
                />
              </li>
            </ul>
            <div className="flex gap-[30px] mt-3">
              <a
                href="https://www.facebook.com/Terces1555"
                className="bg-white p-[6px] rounded-[15px] t-footer-icon"
                id="mission-div"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className="text-[#002549] fb " />
              </a>
              <ReactTooltip
                anchorId="mission-div"
                place="top"
                content="Like Us"
                className="footer-tooltip"
              />
              <a
                href="https://www.youtube.com/@TercesSolutionsPrivatelimited/channels"
                className="bg-white p-[6px] rounded-[15px] t-footer-icon"
                id="mission-div1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="text-[#002549] yt" />
              </a>
              <ReactTooltip
                anchorId="mission-div1"
                place="top"
                content="Subscribe Us"
                className="footer-tooltip"
              />
              <a
                href="https://www.instagram.com/terces_solutions/"
                className="bg-white p-[6px] rounded-[15px]  t-footer-icon"
                id="mission-div2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiInstagramFill className="text-[#002549] insta" />
              </a>
              <ReactTooltip
                anchorId="mission-div2"
                place="top"
                content="Follow Us"
                className="footer-tooltip"
              />
              <a
                href="https://twitter.com/terceS_sol"
                className="bg-white p-[6px] rounded-[15px] t-footer-icon"
                id="mission-div3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="text-[#002549] twitter" />
              </a>
              <ReactTooltip
                anchorId="mission-div3"
                place="top"
                content="Follow Us"
                className="footer-tooltip"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footerr;
