import React from "react";
import Footerr from "../components/Footerr/Footerr";
import Form from "../components/Form/Form";
import { Header } from "../components/shared";

function RecruitmentForm() {
  return (
    <div>
      <Header />
      <div className=" mb-10 ">
        <Form />
        <Footerr />
      </div>
    </div>
  );
}

export default RecruitmentForm;
