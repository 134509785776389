import React from "react";
import styled from "styled-components";
import { Description } from "../HeroSection/HeroSection";
import image from "../History/bg2.img.webp";
import "./Service.css";

import { SlArrowRight } from "react-icons/sl";

const Service = ({ dataa }) => {
  return (
    <div>
      <Container id="service">
        <Heading>
          <SubTitle>
            <Line /> Offerings
          </SubTitle>
          <Title>Terces Solutions</Title>
        </Heading>
        <Body>
          {dataa[0].data.map((item) => {
            return (
              <ItemWraper>
                <Item className="solutions-box">
                  {/* <div className="solutions-icon"> {item.i}</div> */}
                  <img src={item.i1} alt="" className="w-[65%]" />
                  <ItemTitle
                    className="text-hover-from-left"
                    data-link={item.s}
                  >
                    {item.s}
                  </ItemTitle>
                  <ItmeDescription>{item.c}</ItmeDescription>
                  <Action>
                    {/* <div className="flex mt-[10px] p-[10px] pt-0">
                      <SlArrowRight className="bg-[#0085CA] p-[8px] text-[42px] text-white" />
                      <button className="btn-side btn-11 hover-filled-slide-right">
                        <span>READ MORE</span>
                      </button>
                    </div> */}
                  </Action>
                </Item>
              </ItemWraper>
            );
          })}
        </Body>{" "}
        <br />
      </Container>
    </div>
  );
};

export default Service;

const Container = styled.section`
  padding: 3rem 6rem 0rem 7rem;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 1910px) {
    padding: 20px 17rem;
  }

  @media (max-width: 1200px) {
    padding: 3rem 5rem 6rem;

    padding-top: 4rem;
    margin-top: 1rem;
  }
  @media (max-width: 900px) {
    padding: 3rem 4rem 6rem;
    flex-direction: column;

    padding-top: 4rem;
    margin-top: 1rem;
  }
  @media (max-width: 800px) {
    padding: 3rem 4rem 6rem;
    flex-direction: column;

    padding-top: 4rem;
    margin-top: 1rem;
  }
  @media (max-width: 700px) {
    padding: 3rem 1rem;

    padding-top: 6rem;
    margin-top: 0rem;
  }
`;
const Heading = styled.div`
  padding: 2rem 0rem 9rem;

  @media (max-width: 900px) {
    padding: 0rem 1rem 1rem;
  }
`;
export const SubTitle = styled.h4`
  font-size: 28px;
  font-weight: 500;
  color: var(--font-title);

  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    font-size: 22px;
    margin-bottom: 0.6rem;
  }
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;
export const Line = styled.span`
  width: 65px;
  height: 2px;
  background: #002549;
  display: inline-block;
  margin-right: 20px;
`;
export const Title = styled.h2`
  font-size: 60px;
  line-height: 72px;
  color: var(--font-title);
  font-weight: 800;
  font-family: "Open Sans";

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media (max-width: 700px) {
    font-size: 38px !important;
    margin: 10px 0px;
    line-height: 36px;
    line-height: 36px;
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ItemWraper = styled.div`
  &:nth-child(2) {
    margin-top: -120px;
    height: 150px;
    @media (max-width: 900px) {
      margin-top: 0px;
    }
  }
  &:nth-child(3) {
    margin-top: -240px;

    @media (max-width: 900px) {
      margin-top: 0px;
    }
  }
`;

const Item = styled.div`
  text-align: center;
  background: aliceblue;
  padding: 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1200px) {
    padding: 4rem 2rem;
  }
  @media (max-width: 900px) {
    padding: 4rem 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    padding: 4rem 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 700px) {
    padding: 4rem 2rem;
  }

  img {
  }
`;
const ItemTitle = styled.h3`
  margin-top: 1rem;
  font-size: 22px;
  font-weight: 600;
  color: var(--font-title);
  font-family: "Oswald";
  padding: 5px 10px;
`;
const ItmeDescription = styled.p`
  color: var(--text);
  margin: 1.5rem 0rem;
  width: 80%;
  font-family: "Open Sans";
  line-height: 170%;
  font-size: 15px;
`;
const Action = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--font-title);
`;
const ActionLine = styled.span`
  width: 30px;
  height: 2px;
  background: #002549;
  display: inline-block;
  margin-left: 15px;
`;
