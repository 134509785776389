export let stepThree = [
  {
    type: "ConditionalRadioButton",
    fieldLabel: "Choose your first Interest",
    stateName: "firstNonTechnicalSecondaryCommittee",
    options: [
      "Technical",
      "Marketing",
      "Documentation",
      "Business",
      "Operations",
    ],
  },
  {
    type: "ConditionalRadioButton",
    fieldLabel: "Choose your second Interest.",
    stateName: "secondNonTechnicalSecondaryCommittee",
    options: [
      "Technical",
      "Marketing",
      "Documentation",
      "Business",
      "Operations",
    ],
  },
];
