import React from "react";
import { useParams } from "react-router-dom";
import Footerr from "../Footerr/Footerr";
import HeroOther2 from "../HeroOther/HeroOther2";
import { Header } from "../shared";
import { Services } from "../WhyTerces/WhyTercesData";
import IndividualService from "./IndividualService";
import HeroOther from "../HeroOther/HeroOther";

const HeroOtherData = {
  id: 1,
  h1: "Empowering Restaurants",
  s1: "The Food & Beverage Innovators",
  c1: "At Terces, we're devoted to delivering exceptional results. Our success stems from core values, excellence culture, and process-tech expertise.",
  c2: "We empower client growth via optimized operations, streamlined processes, and personalized experiences. Our values ensure client-centricity and excellence.",
};

function SingleService() {
  const { productId } = useParams();
  const thisProduct = Services.find((prod) => prod.id === parseInt(productId));

  if (thisProduct) {
    // Access the properties of `thisProduct` here
    // For example:
    console.log(thisProduct.id);
    console.log(thisProduct.description);
    // ...
  } else {
    // Handle the case where `thisProduct` is undefined
    // For example:
    console.log("Product not found");
  }
  if (!thisProduct) {
    console.log(`Product with ID ${productId} not found.`);
  } else {
    console.log(thisProduct.Name);
  }
  if (!thisProduct) {
    return <div></div>;
  }
  return (
    <div>
      <Header />
      <HeroOther item={HeroOtherData} />
      <IndividualService item={thisProduct} />
      <Footerr />
    </div>
  );
}

export default SingleService;
