import React from "react";
import Footerr from "../components/Footerr/Footerr";

import { About, History, WhyUs } from "../components/home";
import { DataSolutionsPagePrint } from "../components/home/BluePrint/Data";
import { DataSolutionsPageHistory } from "../components/home/History/Data";
import { Header } from "../components/shared";

function ServicesPage() {
  return (
    <div>
      <Header />
      <History data={DataSolutionsPageHistory} head={DataSolutionsPagePrint} />
      <Footerr />
    </div>
  );
}

export default ServicesPage;
