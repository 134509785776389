import React, { useContext } from "react";
import FormDataContext from "./formContext";

import { SlArrowRight } from "react-icons/sl";

function MainStepper({ handleSubmit }) {
  let { step, setStep, setSubmitPopup, setStepSwitchErrorPopup } =
    useContext(FormDataContext);

  let onValid = () => {
    setStep(step + 1);
  };

  let onError = () => {
    setStepSwitchErrorPopup(true);
  };

  let onSubmit = () => {
    setSubmitPopup(true);
  };

  let nextButton = () => {
    return (
      <div className="flex mt-[10px] p-[10px] pt-0">
        <SlArrowRight className="bg-[#0085CA] p-[8px] text-[42px] text-white" />
        <button
          className="btn-side btn-11 hover-filled-slide-right"
          onClick={handleSubmit(onValid, onError)}
        >
          <span>NEXT STEP</span>
        </button>
      </div>
    );
  };

  let backButton = () => {
    return (
      <button onClick={() => setStep(step - 1)} className="secondaryButton">
        Back
      </button>
    );
  };

  let submitButton = () => {
    return (
      <button
        onClick={handleSubmit(onSubmit, onError)}
        className="primaryButton"
      >
        Submit
      </button>
    );
  };

  let sutibleStepButtons = () => {
    if (step === 1) {
      return <div className="text-right">{nextButton()}</div>;
    } else if (step === 6) {
      return (
        <>
          <div>{backButton()}</div>
          <div>{submitButton()}</div>
        </>
      );
    } else {
      return (
        <>
          <div>{backButton()}</div>
          <div>{nextButton()}</div>
        </>
      );
    }
  };

  return (
    <div className="mainStepperButtonsContainer">{sutibleStepButtons()}</div>
  );
}

export default MainStepper;
