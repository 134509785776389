import DigitalMarketing from "./Media/New/DigitalMarketing.png";
import Promotions from "./Media/New/Promotions&Offers.png";
import EmployeeTraining from "./Media/New/EmployeeTraining.png";
import NewProduct from "./Media/New/NewProduct.png";
import TechnologyIntegration from "./Media/New/TechnologyIntegration.png";
import ToolsIntegration from "./Media/New/ToolsIntegration.png";
import InfrastructureUpgradation from "./Media/New/InfrastructureUpgradation.png";
import ProductMaster from "./Media/New/ProductMaster.png";
import InfrastructureMaintenance from "./Media/New/InfrastructureMaintenance.png";
import Procurement from "./Media/New/Procurement.png";
import Concepts from "./Media/New/Concepts.png";

export const DataMainBluePrint = [
  {
    h: "Transformation",
    c: "Terces Solutions is dedicated to revolutionizing the way food and beverage businesses operate. ",
  },
  {
    h: "Innovative minds",
    c: "We constantly explore new ideas and push boundaries, driven by a passion for innovation and positive change.",
  },
  {
    h: "Up-to-Date",
    c: "We lead the food and beverage industry by staying ahead of the curve, and shaping a better future.",
  },
];

// export const DataSolutionsPagePrint = [
//   {
//     h: "POS Management",
//     c: "We help you to choose state-of-the-art point of sale solutions to streamline operations and improve customer service It includes order management, payment processing, inventory tracking, and real-time sales reporting.",
//     i: Pos,
//   },
//   {
//     h: "Digital Marketing",
//     c: "Stay ahead with our digital marketing. Customized solutions including SEO, PPC, social media, and email marketing to reach your audience, increase visibility, and drive conversions.",
//     i: Digital,
//   },
//   {
//     h: "Stock Forecasting",
//     c: "We provide accurate predictions using cutting-edge technology and tailored solutions that minimize risk and maximize profits. Our data-driven approach keeps you ahead of the game, helping you achieve your financial goals.",
//     i: Stock,
//   },
//   {
//     h: "New MENU Addition",
//     c: "We use customer insights to identify new additions, optimize pricing, and increase revenue. Our data-driven approach keeps you up-to-date with the latest food trends, giving you a competitive edge.",
//     i: Menu,
//   },
//   {
//     h: "New Revenue lines",
//     c: "Maximize profitability with our new revenue line inputs. We optimize pricing, identify new revenue streams, and streamline operations. Our data-driven approach keeps you ahead of the competition.",
//     i: Revenue,
//   },
//   {
//     h: "Own Web Application ",
//     c: "Custom web applications revolutionize your business. Our tailored solutions are scalable, optimized, and use the latest technology. Trust our expert team for maximum performance. ",
//     i: Website,
//   },
//   {
//     h: "Sales Strategy",
//     c: "Sales analysis service offers comprehensive insights into the performance of your business, including sales trends, customer behavior, market demand.",
//     i: Sales,
//   },
//   {
//     h: "Marketing Strategy",
//     c: "Our market analysis service includes detailed information on the current state of the market, future projections, key players and their strategies, consumer behavior, and much more.",
//     i: Marketing,
//   },
//   {
//     h: "Data Inputs",
//     c: "Drive growth and increase revenue with our data inputs. Our customized solutions and data-driven approach optimize your business with advanced analytics and cutting-edge technology.",
//     i: Data,
//   },
// ];

export const DataSolutionsPagePrint = [
  {
    h: "Digital Marketing",
    c: "Enhance your online presence with our comprehensive digital marketing solutions. We specialize in SEO, PPC, social media marketing, and email campaigns to help you reach your target audience, increase visibility, and drive conversions.",
    i: DigitalMarketing, // Please replace 'Pos' with the appropriate image variable.
  },
  {
    h: "Employee Training",
    c: "Empower your workforce with our training programs. We offer customized training modules to equip your employees with the knowledge and skills they need to excel in their roles.",
    i: EmployeeTraining, // Please replace 'Digital' with the appropriate image variable.
  },
  {
    h: "Product Master Standardisation",
    c: "Ensure consistency and quality in your product offerings with our product master standardization services. We help you create and maintain standardized product data for improved efficiency.",
    i: ProductMaster, // Please replace 'Stock' with the appropriate image variable.
  },
  {
    h: "Technology Integration",
    c: "Seamlessly integrate the latest technology into your operations with our expert guidance. We tailor technology solutions to your specific needs, enhancing productivity and customer experience.",
    i: TechnologyIntegration, // Please replace 'Menu' with the appropriate image variable.
  },
  {
    h: "Tools Integration",
    c: "Optimize your workflow with our tools integration services. We integrate essential tools and software to streamline your processes and boost efficiency.",
    i: ToolsIntegration, // Please replace 'Revenue' with the appropriate image variable.
  },
  {
    h: "Infrastructure Maintenance Plan",
    c: "Ensure the reliability and longevity of your infrastructure with our maintenance plans. We offer proactive maintenance strategies to prevent downtime and costly repairs.",
    i: InfrastructureMaintenance, // Please replace 'Website' with the appropriate image variable.
  },
  {
    h: "Infrastructure Upgradation",
    c: "Stay competitive by upgrading your infrastructure. We provide expert guidance and support for upgrading your technology and facilities to meet evolving market demands.",
    i: InfrastructureUpgradation, // Please replace 'Sales' with the appropriate image variable.
  },
  {
    h: "New Product Infusion",
    c: "Inject innovation into your product lineup with our new product infusion services. We analyze market trends and customer preferences to help you introduce exciting new offerings.",
    i: NewProduct, // Please replace 'Marketing' with the appropriate image variable.
  },
  {
    h: "Promotions & Offers Management",
    c: "Maximize sales and customer engagement with our promotions and offers management. We create and manage effective promotional campaigns to drive revenue growth.",
    i: Promotions, // Please replace 'Data' with the appropriate image variable.
  },
  {
    h: "Procurement Streamlining",
    c: "Optimize your procurement processes with our streamlining solutions. We help you source materials efficiently, reduce costs, and enhance supplier relationships.",
    i: Procurement, // Please replace 'Data' with the appropriate image variable.
  },
  {
    h: "Concepts Initialisation",
    c: "Transform your concepts into reality with our initialization services. We assist in turning your ideas into actionable plans, guiding you through the initial stages of implementation.",
    i: Concepts, // Please replace 'Data' with the appropriate image variable.
  },
];
