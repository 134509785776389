import React from "react";
import Footerr from "../components/Footerr/Footerr";
import { Team } from "../components/home";
import { Header } from "../components/shared";
import SocialMedia from "../components/SocialMedia/SocialMedia";
import { TeamData } from "./../components/SocialMedia/SocialMediaData";

function TeamPage() {
  return (
    <div>
      {" "}
      <Header />
      <div className="">
        <Team />

        <SocialMedia
          team="Skilled Team"
          data={TeamData}
          desc="Our team ensures your food and beverage data is organized, secure, and optimized for efficient decision-making and industry compliance."
        />
        <Footerr />
      </div>
    </div>
  );
}

export default TeamPage;
