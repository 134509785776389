import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import "./CareerApply.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import TextTransition, { presets } from "react-text-transition";
// Move particlesInit and particlesLoaded outside the component

import heroimg from "./media/careerhero.png";

const TEXTS = ["We are hiring", "Join Terces family"];

const particlesInit = async (main) => {
  console.log(main);
  await loadFull(main);
};

const particlesLoaded = (container) => {
  console.log(container);
};

const CareerApply = () => {
  const [index1, setIndex] = React.useState(0);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index1) => index1 + 1),
      4000 // every 3 seconds
    );
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    particlesInit("tsparticles"); // Initialize particles using particlesInit function
  }, []);

  return (
    <div className="career-card-12 ">
      <div className=" bg-[#F8FAFC]  careers1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
          <div className="flex flex-col h-full justify-center">
            <div className="w-16">
              <Link to="/"></Link>
            </div>
            <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-[40px] mt-6 sm:mt-10 !leading-[1.13] tracking-tight font-['Open_Sans''] text-white">
              Step into a world of wonder
              <br />
              Join us for a wonderful journey.
            </h2>{" "}
            <span className="block mt-6 text-slate-500 dark:text-slate-400 paragraph-white">
              Join a dynamic, expanding team of professionals that provides
              excellence.
            </span>
            <ul className="space-y-4 mt-10 font-['Open_sans']">
              <li className="flex items-center space-x-4">
                <span className="nc-Badge inline-flex px-[5px] py-1 rounded-full font-medium text-xs  text-black bg-[white]  relative">
                  01
                </span>
                <span className="font-medium text-black dark:text-neutral-300 text-white">
                  Boundless opportunity
                </span>
              </li>
              <li className="flex items-center space-x-4">
                <span className="nc-Badge inline-flex px-[5px] py-1 rounded-full font-medium text-xs  text-black bg-[white]  relative">
                  02
                </span>
                <span className="font-medium text-black dark:text-neutral-300 text-white">
                  Build Your Future
                </span>
              </li>
              <li className="flex items-center space-x-4">
                <span className="nc-Badge inline-flex px-[5px] py-1 rounded-full font-medium text-xs relative text-black bg-[white]">
                  03
                </span>
                <span className="font-medium text-black dark:text-neutral-300 text-white">
                  Minimize Disruption
                </span>
              </li>
            </ul>
            <Link to="/Terces_Recruitment" className="mt-[30px]">
              {" "}
              {/* <button class="custom-btn main-btn">Explore Us</button> */}
              <button className="hero-btn12">
                <TextTransition
                  springConfig={presets.wobbly}
                  className="why-terces-btn !text-white !pl-0"
                >
                  {TEXTS[index1 % TEXTS.length]}
                </TextTransition>

                <span className="my-auto ml-[8px]">
                  <BsArrowRight className="text-[#00a9f4] text-[22px]" />
                </span>
              </button>
            </Link>{" "}
          </div>

          <div className=" mt-10 lg:mt-0">
            <img src={heroimg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerApply;
