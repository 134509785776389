// ---- Importing required dependencies (External)——
// ---- Packages ----
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from "react-responsive";

// ---- Importing required dependencies (Local)----
// ---- Styles ----
import "./MissionVision.css";

// Mission content
const missionContent = (
  <div style={{ padding: "10px" }}>
    <h1 className="heading !text-[#278cd2] mb-[10px]">Our Vision</h1>
    {/* Vision description */}
    <p style={{ marginBottom: "10px" }} className="paragraph font-thin">
      Our mission is clear - to provide a comprehensive suite of solutions to
      gold businesses that are designed to optimize operations, increase
      efficiency, and drive growth.
    </p>
    <p style={{ marginBottom: "10px" }} className="paragraph font-thin">
      We understand that the gold business is highly competitive, and that
      stakeholders need a way to stand out from the crowd. That's why our Gold
      Domain platform offers the stakeholders the opportunity to create a unique
      online presence with the .Gold extension.
    </p>
  </div>
);

// Who we are content
const whoContent = (
  <div style={{ padding: "10px" }}>
    <h1 className="heading !text-[#278cd2] mb-[10px]">Who we are</h1>
    {/* Description */}
    <p style={{ marginBottom: "10px" }} className="paragraph font-thin">
      GetBrand .Gold was founded by a team of experienced professionals with
      over 20 years of experience in the gold business and IT. Our team is
      dedicated to providing the best possible service and support to our gold
      business community, ensuring they are equipped with the latest digital
      tools and technologies to succeed.
    </p>
    <p style={{ marginBottom: "10px" }} className="paragraph font-thin">
      We value transparency above all else, and our team work closely with our
      clients to create websites that meet their unique needs and reflect their
      brand. Whether you're a small-scale gold business or a large corporation,
      we are committed to helping you achieve your business objectives.
    </p>
  </div>
);

// What we do content
const whatContent = (
  <div style={{ padding: "10px" }}>
    <h1 className="heading !text-[#278cd2] mb-[10px]">What We do</h1>
    {/* Description */}
    <p style={{ marginBottom: "10px" }} className="paragraph font-thin">
      At GetBrand .Gold, we specialize in providing a range of services to
      support gold businesses in their digital endeavors. Our main offering is
      selling .Gold domains, which provide a powerful tool for gold traders to
      create a unique online presence and differentiate themselves from their
      competitors.
    </p>
    <p style={{ marginBottom: "10px" }} className="paragraph font-thin">
      In addition to our domain services, we also offer hosting services for
      reliable website performance, CRM handling for effective customer
      relationship management, SEO strategies to improve online visibility, and
      marketing consultation to increase revenue.
    </p>
  </div>
);

// ---- Main function -- for About US Page.
function Company() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="company-section">
      {/* Vision Section */}
      <div>
        <h1 className="main-title text-center mx-0 px-0 mb-[20px]">
          Our Vision
        </h1>
        {/* Vision description */}
        <p className="paragraph  w-[95%] !text-center">
          We empower food and retail enterprises with specialised solutions
          derived from deep industry insight. Our seasoned team tailors
          strategies, optimises operations, refines brand identities, and
          deploys digital expertise to reshape success in the dynamic world of
          food and retail.
        </p>
        {/* Vision sub-sections */}
        <div className="vision-inner-div">
          {/* Mission Section */}
          <div className="vision-inner-div1">
            <div className="vision-inner-div2" id="mission-div18">
              <h1 className="heading mx-0 px-0 mt-[40px] mb-[10px]">
                Our Mission
              </h1>
              {/* Mission description */}
              <p className="paragraph w-[95%] !text-center circle-para">
                To assist restaurants and supermarkets in their journey of
                success.
              </p>
            </div>
            {/* Mission Tooltip */}
            <ReactTooltip
              anchorId="mission-div18"
              place={isMobile ? "bottom" : "right"}
              content={missionContent}
              className="copy-box-home-vision"
              variant="error"
              opacity={1}
            />
          </div>
          {/* Who we are Section */}
          <div className="vision-inner-div1">
            <div className="vision-inner-div2 " id="who-we-div">
              <h1 className="heading mx-0 px-0 mt-[40px] mb-[10px]">
                Who we are
              </h1>
              {/* Who we are description */}
              <p className="paragraph w-[95%] !text-center  circle-para">
                Tech enthusiasts aimed to solve problems of restaurants and
                supermarkets.
              </p>
            </div>
            {/* Who we are Tooltip */}
            <ReactTooltip
              anchorId="who-we-div"
              place={isMobile ? "bottom" : "right"}
              content={whoContent}
              className="copy-box-home-vision"
              variant="error"
              opacity={1}
            />
          </div>
          {/* What we do Section */}
          <div className="vision-inner-div1">
            <div className="vision-inner-div2" id="what-we-div">
              <h1 className="heading mx-0 px-0 mt-[40px] mb-[10px]">
                What We do
              </h1>
              {/* What we do description */}
              <p className="paragraph w-[95%] !text-center  circle-para">
                We give technology services to restaurants and supermarkets.
              </p>
            </div>
            {/* What we do Tooltip */}
            <ReactTooltip
              anchorId="what-we-div"
              place={isMobile ? "top" : "left"}
              content={whatContent}
              className="copy-box-home-vision tip3"
              opacity={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
