export let stepOnePartOne = [
  {
    type: "text",
    fieldLabel: "Full name",
    stateName: "fullName",
    pattern: /\b[a-zA-Z]+\s[a-zA-Z]+\b/,
    patternErrMsg:
      "Full name should be at least two words and shouldn't include any special charachters",
  },
  {
    type: "text",
    fieldLabel: "Mother name",
    stateName: "mothername",
    pattern: /\b[a-zA-Z]+\s[a-zA-Z]+\b/,
    patternErrMsg:
      "Mother name should be at least two words and shouldn't include any special charachters",
  },
  {
    type: "text",
    fieldLabel: "Phone number",
    stateName: "phoneNumber",
    pattern: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g,
    patternErrMsg: "Write a valid phone number (10 digits)",
  },
];

export let stepOnePartTwo = [
  {
    type: "text",
    fieldLabel: "Father name",
    stateName: "fathername",
    pattern: /\b[a-zA-Z]+\s[a-zA-Z]+\b/,
    patternErrMsg:
      "Father name should be at least two words and shouldn't include any special charachters",
  },
  {
    type: "text",
    fieldLabel: "Address",
    stateName: "address",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid adddress",
  },
  {
    type: "text",
    fieldLabel: "Email",
    stateName: "email",
    pattern:
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    patternErrMsg: "Write a valid email",
  },
];

export let stepOnePartThree = [
  {
    type: "radioButton",
    fieldLabel: "Previous work experience",
    stateName: "experience",
    options: ["Yes", "No"],
  },
  {
    type: "ConditionalText",
    fieldLabel: "Provide your experience details",
    stateName: "experienceDetails",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
  {
    type: "textArea",
    fieldLabel: "State any skills you have.",
    stateName: "skills",
    pattern: /[a-z]/i,
    patternErrMsg: "Wirte a valid input",
  },

  {
    type: "textArea",
    fieldLabel: "State your hobbies.",
    stateName: "hobbies",
    pattern: /[a-z]/i,
    patternErrMsg: "Wirte a valid input",
  },
  {
    type: "textArea",
    fieldLabel: "How would you describe yourself.",
    stateName: "describeYourself",
    pattern: /[a-z]/i,
    patternErrMsg: "Wirte a valid input",
  },
  {
    type: "textArea",
    fieldLabel:
      "What achievemenets have you accomplished throughout your life.",
    stateName: "achievemenets",
    pattern: /[a-z]/i,
    patternErrMsg: "Wirte a valid input",
  },
];
