import React, { useContext } from "react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import FormDataContext from "./formContext";
import SwitchErrorPopup from "./SwitchErrorPopup";
import SpecialStepper from "./SpecialStepper";
import SpecialStepperErrorMessage from "./SpecialStepperErrorMessage";
import SamePageErrorMessage from "./SamePageErrorMessage";
import FinishPopup from "./FinishPopup";
import "./Form.css";
import HeroOther from "../HeroOther/HeroOther";

function Form() {
  let { step } = useContext(FormDataContext);

  let steps = () => {
    switch (step) {
      case 1:
        return <FormStep1 />;
      case 2:
        return <FormStep2 />;
      case 3:
        return <FormStep3 />;
      case 4:
        return <FormStep4 />;
      case 5:
        return <FormStep5 />;
      case 6:
        return <FormStep6 />;
      default:
        throw new Error();
    }
  };
  return (
    <>
      <SamePageErrorMessage />
      <SwitchErrorPopup />
      <SpecialStepperErrorMessage />
      <FinishPopup />
      <div className="Hero-Recruitment">
        <h1 className=" font-bold  recruitment-title">TERCES RECRUITMENT</h1>
      </div>
      <div className="Recruitment1">
        <div className="formTitle">
          <center>
            <div>
              <b className="font-[Inter] text-[28px]">
                Follow the simple 6 steps to complete the form.
              </b>
            </div>
          </center>
        </div>

        <div className="recruitmentContainer">
          <SpecialStepper />
          {steps()}
        </div>
      </div>
    </>
  );
}

export default Form;
