import React from "react";

import Footerr from "../components/Footerr/Footerr";
import MissionVision from "../components/MissionVission/MissionVision";
import Reception from "../components/Reception/Reception";
import { Header } from "../components/shared";

function StoryPage() {
  return (
    <div>
      <Header />
      <Reception />
      <MissionVision />
      <Footerr />
    </div>
  );
}

export default StoryPage;
