import React from "react";
import { Services } from "../WhyTerces/WhyTercesData";
import "./SingleService.css";

function IndividualService({ item }) {
  const { id: productId } = item;

  // Find the current product by its ID
  const thisProduct = Services.find((prod) => prod.id === parseInt(productId));

  // Check if the product exists
  if (!thisProduct) {
    console.log(`Product with ID ${productId} not found.`);
    return null; // Return null or handle the error condition appropriately
  } else {
    console.log(thisProduct.item);
  }

  const { id: currentDomainId } = thisProduct; // Update the destructuring to use 'id'

  // Filter out the remaining domains
  const remainingServices = Services.filter(
    (item) => item.id !== currentDomainId
  );
  return (
    <div className="t-padding indi-domain-part">
      <div className="indi-right">
        {/* Individual domain explanation Part */}
        <div className="service-img-div">
          <img src={item.image} alt="" className="service-img" />
          <h1 className="main-service">{item.item}</h1>
        </div>
        <div className="indi-right-content-part">
          <br />
          {item.content()}
        </div>
      </div>
      {/* <div className="indi-left">
        <h1 className="heading min-[768px]:mb-[20px] !text-[#54b444] !text-[40px] !font-bold !pl-[10px]">
          Restaurant Services
        </h1>
        <h1 className="current-service !pl-[10px]">{item.item}</h1>
        <ul className="domains-list">
          {remainingServices.map((service) => {
            if (service.id !== parseInt(productId)) {
              return (
                <li key={service.id} className="domain-list-item">
                  <Link
                    to={`/SingleService/${service.id}`}
                    className="domain-link"
                  >
                    <div className="flex gap-[10px]">
                      <span className="!pl-[10px]">{service.item}</span>
                    </div>
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div> */}
    </div>
  );
}

export default IndividualService;
