import React from "react";

function StepIcon({ Icon, title, step, id }) {
  // console.log(id);
  return (
    <div className="bigSpecialStepperButtonContainer">
      <div
        className={`bigSpecialStepperIconContainer ${
          id == step
            ? "bigSpecialStepperActiveIconContainer"
            : id > step
            ? "bigSpecialStepperDisabledIconContainer"
            : ""
        }`}
      >
        <Icon
          fill={id == step ? "#002549" : id > step ? "#173152" : "#173152"}
        />
      </div>
      <span
        className={
          id == step
            ? "bigSpecialStepperButtonLabel"
            : id > step
            ? "bigSpecialStepperDisapbledButtonLabel"
            : "bigSpecialStepperInactiveButtonLabel"
        }
      >
        {title}
      </span>
    </div>
  );
}

export default StepIcon;
