import "./App.scss";
import { Header } from "./view/components/shared";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import BlogPage from "./view/Pages/BlogPage";
import TeamPage from "./view/Pages/TeamPage";
import StoryPage from "./view/Pages/StoryPage";
import ServicesPage from "./view/Pages/ServicesPage";
import SolutionsPage from "./view/Pages/SolutionsPage";
import Homer from "./view/Pages/Homer";
import ContactPage from "./view/Pages/ContactPage";
import CareerPage from "./view/Pages/CareerPage";
import "./App.scss";
import RecruitmentForm from "./view/Pages/RecruitmentForm";
import TopNav from "./view/components/shared/Header/TopNav";
import { createContext, useLayoutEffect, useState } from "react";
import SingleBlog from "./view/components/LatestBlog/SingleBlog";
import SingleService from "./view/components/SingleService/SingleService";
import SingleSolution from "./view/components/SingleSolution/SingleSolution";

export const LangContext = createContext();

function App() {
  const [langEng, setLangEng] = useState(true);
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <div className="App">
      <LangContext.Provider value={{ langEng, setLangEng }}>
        <BrowserRouter>
          <Wrapper>
            {/* <TopNav /> */}
            <Routes>
              <Route exact path="/" element={<Homer />} />
              <Route exact path="/Contact" element={<ContactPage />} />
              <Route exact path="/Blogs" element={<BlogPage />} />
              <Route exact path="/Careers" element={<CareerPage />} />
              <Route exact path="/Team" element={<TeamPage />} />
              <Route exact path="/Ourstory" element={<StoryPage />} />
              <Route exact path="/Services" element={<ServicesPage />} />
              <Route path="/Blogs/:productId" element={<SingleBlog />} />
              <Route exact path="/Solutions" element={<SolutionsPage />} />
              <Route
                exact
                path="/Terces_Recruitment"
                element={<RecruitmentForm />}
              />
              <Route
                path="/SingleService/:productId"
                element={<SingleService />}
              />
              <Route
                path="/SingleSolution/:productId"
                element={<SingleSolution />}
              />
            </Routes>
          </Wrapper>
        </BrowserRouter>
      </LangContext.Provider>
    </div>
  );
}

export default App;
