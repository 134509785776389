export const DataHomerHistory = [
  {
    h1: "Who We Are - Happy to Introduce our Selves",
    s1: "The Food & Beverage Innovators",
    c1: "We are a passionate group of professionals who deal with the development of process and technology solutions for food and beverages industry. We focus on  innovation, quality, and vision of shaping the future of the industry.",
    c2: "Our team combines expertise, experience, and a commitment to excellence to deliver unparalleled solutions.",
  },
];

export const DataStoryPageHistory = [
  {
    h1: "Happy to Introduce our Selves",
    s1: "Beverage Innovators",
    c1: "We are a passionate group of professionals who deal with the development of process and technology solutions for food and beverages industry. We focus on  innovation, quality, and vision of shaping the future of the industry.",
    c2: "Our team combines expertise, experience, and a commitment to excellence to deliver unparalleled solutions.",
  },
];

export const DataSolutionsPageHistory = [
  {
    h1: "Quality Services",
    s1: "Pioneers in Food & Retail Businesses",
    c1: "Terces is a team of passionate professionals that provides quality services for the food and beverage industry. ",
    c2: " Our expertise and experience allow us to develop innovative processes and technologies tailored to meet our clients' unique needs. Here is list of services offered by Terces.",
  },
];
