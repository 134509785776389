export let stepFour = [
  {
    type: "specialRange",
    fieldLabel: "Technical",
    stateName: "Technical",
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    type: "specialRange",
    fieldLabel: "Marketing",
    stateName: "Marketing",
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    type: "specialRange",
    fieldLabel: "Documentation",
    stateName: "Documentation",
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    type: "specialRange",
    fieldLabel: "Business",
    stateName: "Business",
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    type: "specialRange",
    fieldLabel: "Operations",
    stateName: "Operations",
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    type: "specialRange",
    fieldLabel: "Communication",
    stateName: "Communication",
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
];
