import React from "react";
import "./WhyTercesHome.css";
import { BlogData } from "./WhyTercesHomeData";
import { Tilt } from "react-tilt";

function WhyTercesHome() {
  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 10, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };
  return (
    <div className="why-part">
      <h1 className="main-title ">Why Choose Us</h1>
      <p className="paragraph mt-[20px]">
        Transform your enterprise with Terces, a beacon of innovation,
        transparency, and integrity. Our smart solutions redefine excellence for
        restaurants and supermarkets, setting new standards in the industry.
      </p>
      <hr className="my-[30px] bg-[white] h-[2px]" />
      <div>
        <ul className="why-items">
          {BlogData.map((why) => (
            <Tilt options={defaultOptions} className="why-item ">
              <li
                key={why.id}

                // style={{ backgroundImage: `url(${why.image})` }}
              >
                <div className="why-item-image-div">
                  <img src={why.image} alt="" className="why-image" />
                </div>
                <div className="why-content">
                  <h3 className="why-heading">{why.name}</h3>
                  <p className="why-paragraph">{why.description}</p>
                </div>
              </li>
            </Tilt>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default WhyTercesHome;
