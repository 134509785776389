export let stepFive = [
  {
    type: "text",
    fieldLabel: "Are you willing to relocate?",
    stateName: "okrelocate",
    patternErrMsg: "write a valid input",
  },
  {
    type: "textArea",
    fieldLabel: "Are you willing to travel?",
    stateName: "oktravel",
    pattern: /[a-z]/i,
    patternErrMsg: "write a valid input",
  },
  {
    type: "textArea",
    fieldLabel: "Are you authorized to work in this country?",
    stateName: "okcountry",
    pattern: /[a-z]/i,
    patternErrMsg: "write a valid input",
  },
  {
    type: "textArea",
    fieldLabel:
      "Would you rather always be your own boss? And how do you react to taking orders or tasks?",
    stateName: "ratherBeYourOwnBoss",
    pattern: /[a-z]/i,
    patternErrMsg: "write a valid input",
  },
  {
    type: "textArea",
    fieldLabel: "What sets you apart from other candidates?",
    stateName: "setsYouApartFromCandidates",
    pattern: /[a-z]/i,
    patternErrMsg: "write a valid input",
  },
];
