import React from "react";
import Footerr from "../components/Footerr/Footerr";

import LatestBlog from "../components/LatestBlog/LatestBlog";
import { Header } from "../components/shared";

function BlogPage() {
  return (
    <div>
      {" "}
      <Header />
      <div className="">
        <LatestBlog />

        <Footerr />
      </div>
    </div>
  );
}

export default BlogPage;
