import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Fade as Hamburger } from "hamburger-react";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdMiscellaneousServices } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";

import { RiLightbulbFlashFill, RiTeamFill } from "react-icons/ri";
import { GiOpenBook } from "react-icons/gi";
import { FaBloggerB } from "react-icons/fa";
import logo from "./media/latestwhitelogo.png";
import logo2 from "./media/latestlogonew.png";
import $ from "jquery";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [shouldAnimate, setShouldAnimate] = useState(false); // Change initial state

  useEffect(() => {
    setShouldAnimate(true); // Start animation immediately

    const handleScroll = () => {
      setShouldAnimate(window.scrollY < 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".header-wrapper").addClass("white")
        : $(".header-wrapper").removeClass("white");
    });
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".mobile-div").addClass("white")
        : $(".mobile-div").removeClass("white");
    });
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".menu-icon").addClass("scrolled")
        : $(".menu-icon").removeClass("scrolled");
    });
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".search-btn").addClass("scrolled")
        : $(".search-btn").removeClass("scrolled");
    });
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".d-menu").addClass("addon")
        : $(".d-menu").removeClass("addon");
    });
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".d-menu-who").addClass("addon")
        : $(".d-menu-who").removeClass("addon");
    });
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".show-text").addClass("one")
        : $(".show-text").removeClass("one");
    });
  }, []);

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  });

  const MenuLinks = () => {
    return (
      <>
        <Link class="menu-icon " id="company">
          <span className="menu-icon-span">
            Services <IoMdArrowDropdown className="menu-dropdown-icon" />{" "}
          </span>
          <div class="menu-list">
            <nav id="menu-nav" className="dropdown-menu d-menu">
              <Link to={`/Services`} className="dropdown-item">
                <MdMiscellaneousServices className="mr-[10px] td-icon" />
                <p className="drop-text" drop-text>
                  Restaurants
                </p>
              </Link>
              <Link to={`/Solutions`} className="dropdown-item">
                <RiLightbulbFlashFill className="mr-[10px] td-icon" />
                <p className="drop-text">Supermarkets</p>
              </Link>
            </nav>
          </div>
        </Link>
        <Link class="menu-icon" id="domains">
          <span className="menu-icon-span">
            {" "}
            Company
            <IoMdArrowDropdown className="menu-dropdown-icon" />
          </span>
          <div class="menu-list ">
            <nav id="menu-nav-domains" className="dropdown-menu d-menu-who">
              <Link to="/Team" className="dropdown-item">
                <RiTeamFill className="mr-[10px]  td-icon" />
                <p className="drop-text">Terces Team</p>
              </Link>
              <Link to="/Ourstory" className="dropdown-item">
                <GiOpenBook className="mr-[10px] td-icon" />
                <p className="drop-text">Our Story</p>
              </Link>
              <Link to="/Blogs" className="dropdown-item">
                <FaBloggerB className="mr-[10px] td-icon" />
                <p className="drop-text">Blogs</p>
              </Link>
            </nav>
          </div>
        </Link>
        <Link to="/Careers" class="menu-icon">
          <p>Careers</p>
        </Link>
        <Link to="/Contact" class="menu-icon">
          <p>Contact</p>
        </Link>
      </>
    );
  };

  const MobileMenuLinks = () => {
    return (
      <>
        <Link to="/Services" class="menu-icon " id="company">
          Services
        </Link>
        <Link to="/Solutions" class="menu-icon " id="company">
          Solutions
        </Link>
        <Link to="/Team" class="menu-icon " id="company">
          Terces Team{" "}
        </Link>
        <Link to="/Ourstory" class="menu-icon " id="company">
          Our Story{" "}
        </Link>
        <Link to="/Blogs" class="menu-icon " id="company">
          Blogs{" "}
        </Link>

        <Link to="/Careers" class="menu-icon">
          <p>Careers</p>
        </Link>
        <Link to="/Contact" class="menu-icon">
          <p>Contact</p>
        </Link>
      </>
    );
  };

  return (
    <Container>
      <Wraper className={`header-wrapper ${isScrolled ? "white" : ""}`}>
        <Logo>
          <Link to="/">
            <img src={isScrolled ? logo2 : logo} alt="" />
          </Link>
        </Logo>
        <Menu>
          <DesktopMenu>
            <MenuList>
              <MenuLinks />
            </MenuList>
          </DesktopMenu>
          <MobileMenu>
            <Hamburger
              toggled={isMobile}
              toggle={setIsMobile}
              color="#E76F51"
              className="hamburger-react"
            />
          </MobileMenu>
        </Menu>
      </Wraper>
      <MenuInMobile
        isMobile={isMobile}
        className={`header-wrapper ${isScrolled ? "white" : ""}`}
      >
        <MobileMenuBody onClick={() => setIsMobile(false)}>
          <MobileMenuLinks onClick={() => setIsMobile(false)} />
        </MobileMenuBody>
        <MobileMenuFooter>
          <SocialLinks>
            <Link to="#">
              <FaFacebookF />
            </Link>
            <Link to="#">
              <FaTwitter />
            </Link>
            <Link to="#">
              <FaInstagram />
            </Link>
          </SocialLinks>
        </MobileMenuFooter>
      </MenuInMobile>
      <div className="mobile-div"></div>
    </Container>
  );
};

export default Header;

// styled components
const Container = styled.header`
  @media (max-width: 900px) {
    position: fixed;
    top: 0px;
    z-index: 9998;
  }
`;

const Wraper = styled.div`
  padding: 10px 6rem;
  display: flex;
  width: 100%;
  position: fixed;
  // box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  z-index: 9998;
  justify-content: start;
  gap: 40px;
  @media (min-width: 1910px) {
    width: 100%;
    padding: 0.5rem 16rem;
  }
  @media (max-width: 1200px) {
    padding: 1rem 5rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 4rem;
  }
  @media (max-width: 700px) {
    padding: 1rem 0rem;
  }
`;

const Logo = styled.div`
  width: 20%;
  @media (max-width: 700px) {
    width: 75%;
  }

  img {
    width: 14rem;
    height: 3.75rem;
    display: block;
    margin-left: -1rem;
  }
  @media (max-width: 700px) {
    img {
      width: 14rem;
      height: 2.65rem;
      display: block;
      margin-left: 0.5rem;
    }
  }
`;

const Menu = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-left: 40px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  z-index: 10000;
  @media (min-width: 900px) {
    display: none;
  }
`;

const MenuList = styled.nav`
  display: flex;
  gap: 35px;

  a {
    text-decoration: none;
    color: var(--text-white);
    font-size: 16px;
    transition: transform 0.2s;

    &:hover {
      // border-radius:5px;
      // background:#eee;
    }
  }
`;

const MenuInMobile = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: var(--primary-bg);
  transition: all 0.3s ease 0s;
  transform: ${(props) =>
    props.isMobile ? "translateX(0)" : "translateX(100%)"};
`;

const MobileMenuBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 6rem;
  height: 85%;
  a {
    text-decoration: none;
    color: var(--text-white);
    font-size: 20px;
    padding: 10px;
  }
`;

const MobileMenuFooter = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 6rem;
`;

const SocialLinks = styled.div`
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding: 10px;
    color: var(--pent-bg);
  }
`;
