import React, { useState } from "react";
import "./RServices.css";
import { RServicesData } from "./RServicesData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import { Services, SuperSolutions } from "../WhyTerces/WhyTercesData";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import service from "./Media/serviceshome1.png";
import ssolutions from "./Media/ssolutions-removebg.png";

function RServices() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    rtl: true,
    arrows: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    fade: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  let sliderRef;

  return !isMobile ? (
    <div className="r-services-part">
      {/* <div className="r-services-top">Hello</div> */}
      <div className="rservices-main">
        <div className="rservices-left">
          <div className="rservices-left-part">
            <h1 className="main-title ">Elevating Restaurants</h1>
            <hr className="w-[70%] mt-[20px] text-[#E76F51]" />
            <h3 className="category-heading !leading-[45px]">
              Overwhelmed with the complexities of restaurant management?
            </h3>
            <p className="paragraph mt-[10px] mb-[30px]">
              We've got you covered! Terces Solutions is your one-stop solution
              for streamlining restaurant operations, flawless menu executions,
              and efficient management. Join us today? Let us be your partner in
              success! 🚀
            </p>
            <Link to="/Services" className="green-btn">
              KNOW MORE
            </Link>
          </div>

          {/* <Link to="/SingleService/21" className="learn-more-btn">
            Learn More
          </Link> */}
          <Slider
            ref={(slider) => (sliderRef = slider)}
            {...settings}
            className="rservices-left-part-right"
          >
            {Services.slice(0, 1).map((category) => (
              <li key={category.id} className="category-item">
                <Link to={`/SingleService/${category.id}`}>
                  <div className="core-inner">
                    <img src={service} alt="" className="rservices-image" />
                    {/* <div className="category-content2">
                      <h3 className="category-heading ">{category.item}</h3>
                      <p className="category-paragraph mb-[15px]">
                        {category.sd}
                      </p>
                    </div> */}
                  </div>
                </Link>
              </li>
            ))}
          </Slider>
        </div>
        <div className="rservices-right">
          <Slider
            ref={(slider) => (sliderRef = slider)}
            {...settings2}
            className="rservices-left-part-right"
          >
            {SuperSolutions.slice(0, 1).map((category) => (
              <li key={category.id} className="category-item">
                <Link to={`/SingleSolution/${category.id}`}>
                  <div className="core-inner">
                    <img src={ssolutions} alt="" className="rservices-image" />
                    {/* <div className="category-content">
                      <p className="category-paragraph mb-[15px] ">
                        {category.sd}
                      </p>
                    </div> */}
                  </div>
                </Link>
              </li>
            ))}
          </Slider>
          <div className="rservices-left-part">
            <h1 className="main-title super-head ">Boosting Supermarkets</h1>
            <hr className="w-[70%] mt-[20px] text-[#E76F51]" />
            <h3 className="category-heading !leading-[45px]">
              Feeling the strain of juggling supermarket operations ?
            </h3>
            <p className="paragraph mt-[10px] mb-[30px] ">
              No need to worry! Elevating Supermarkets is your comprehensive
              solution for seamlessly managing operations, optimizing inventory,
              and executing sales strategies flawlessly. How can we simplify
              your supermarket experience today? Trust us to be your all-in-one
              solution! 🚀
            </p>
            <Link to={`Solutions`} className="green-btn">
              KNOW MORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="r-services-part">
      {/* <div className="r-services-top">Hello</div> */}
      <div className="rservices-main">
        <div className="rservices-left">
          <div className="rservices-left-part">
            <h1 className="main-title ">Elevating Restaurants</h1>
            <hr className="w-[70%] mt-[20px] text-[#E76F51]" />
            <h3 className="category-heading !leading-[45px]">
              Overwhelmed with the complexities of restaurant management?
            </h3>
            <p className="paragraph mt-[10px] mb-[30px]">
              We've got you covered! Terces Solutions is your one-stop solution
              for streamlining restaurant operations, flawless menu executions,
              and efficient management. Join us today? Let us be your partner in
              success! 🚀
            </p>
            <Link to="/Solutions" className="green-btn">
              KNOW MORE
            </Link>
          </div>

          {/* <Link to="/SingleService/21" className="learn-more-btn">
            Learn More
          </Link> */}
          <Slider
            ref={(slider) => (sliderRef = slider)}
            {...settings}
            className="rservices-left-part-right"
          >
            {Services.slice(0, 1).map((category) => (
              <li key={category.id} className="category-item">
                <Link to={`/SingleService/${category.id}`}>
                  <div className="core-inner">
                    <img src={service} alt="" className="rservices-image" />
                    {/* <div className="category-content2">
                      <h3 className="category-heading ">{category.item}</h3>
                      <p className="category-paragraph mb-[15px]">
                        {category.sd}
                      </p>
                    </div> */}
                  </div>
                </Link>
              </li>
            ))}
          </Slider>
        </div>
        <div className="rservices-right">
          <Slider
            ref={(slider) => (sliderRef = slider)}
            {...settings2}
            className="rservices-left-part-right ssol-mob-img"
          >
            {SuperSolutions.slice(0, 1).map((category) => (
              <li key={category.id} className="category-item">
                <Link to={`/SingleSolution/${category.id}`}>
                  <div className="core-inner">
                    <img src={ssolutions} alt="" className="rservices-image" />
                    {/* <div className="category-content">
                      <p className="category-paragraph mb-[15px] ">
                        {category.sd}
                      </p>
                    </div> */}
                  </div>
                </Link>
              </li>
            ))}
          </Slider>
          <div className="rservices-left-part ssol-mob-content">
            <h1 className="main-title super-head ">Boosting Supermarkets</h1>
            <hr className="w-[70%] mt-[20px] text-[#E76F51]" />
            <h3 className="category-heading !leading-[45px]">
              Feeling the strain of juggling supermarket operations ?
            </h3>
            <p className="paragraph mt-[10px] mb-[30px] ">
              No need to worry! Elevating Supermarkets is your comprehensive
              solution for seamlessly managing operations, optimizing inventory,
              and executing sales strategies flawlessly. How can we simplify
              your supermarket experience today? Trust us to be your all-in-one
              solution! 🚀
            </p>
            <Link to={`/SingleSolution/1`} className="green-btn">
              KNOW MORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RServices;
