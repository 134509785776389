// NewCoreValues.js
import React, { useState } from "react";
import { CoreValuesData } from "./CoreValuesData";
import "./NewCoreValues.css"; // Import your CSS file
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

function NewCoreValues() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const [hoveredItem, setHoveredItem] = useState(null);

  return isMobile ? (
    <div>
      <div className="row core-values-part">
        <div className="col-md-6">
          <div className="core-value-right">
            <h1 className="main-title">Our Core Values</h1>
            <p className="paragraph my-[20px]">
              Happy and Engaged is a state in which we like to operate at
              Terces. Happy employees create a happy environment and happy
              customers. Happy customers are the force behind Sapphire's
              momentum.
            </p>
            <br />
            <Link to="/Contact" className="green-btn">
              Know More
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <ul className="">
            {CoreValuesData.map((category) => (
              <li key={category.id} className="mob-core-value-card">
                <h3 className="mob-core-value-head">{category.name}</h3>
                <p className="mob-core-value-para">{category.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <div className="row core-values-part">
      <div className="col-md-6">
        <ul className="core-values-container">
          {CoreValuesData.map((category) => (
            <li
              key={category.id}
              className={`core-item ${
                hoveredItem === category.id ? "hovered" : ""
              }`}
              onMouseEnter={() => setHoveredItem(category.id)}
              onMouseLeave={() => setHoveredItem(null)}>
              <h3 className="core-heading">{category.name}</h3>
              <p className="core-paragraph paragraph">{category.description}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-md-6">
        <div className="core-value-right">
          <h1 className="main-title">Our Core Values</h1>
          <p className="paragraph my-[20px]">
            Happy and Engaged is a state in which we like to operate at Terces.
            Happy employees create a happy environment and happy customers.
            Happy customers are the force behind Sapphire's momentum.
          </p>
          <br />
          <Link to="/Contact" className="green-btn">
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewCoreValues;
