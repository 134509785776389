import React from "react";
import "./Solutions.css";
import Fade from "react-reveal/Fade";
import { SuperSolutions } from "../WhyTerces/WhyTercesData";
import { Link } from "react-router-dom";

function Solutions() {
  return (
    <div className="solutions-part">
      {SuperSolutions.map((solution, index) => (
        <Fade bottom key={solution.id}>
          <div
            className={`solutions1 ${
              index % 2 === 0 ? "even-item" : "odd-item"
            }`}
          >
            {index % 2 === 1 ? (
              <>
                <Fade left>
                  <div className="solution-content mob-sol-content">
                    <h1 className="heading mt-[20px]">{solution.item}</h1>
                    <hr className="w-[70%] mt-[20px] text-[#E76F51]" />
                    <p className="paragraph solution-data">
                      {solution.description}
                    </p>
                    <p className="paragraph solution-data">
                      {/* {solution.description2} */}
                    </p>
                    <br />
                    <Link
                      // to={`/SingleSolution/${solution.id}`}
                      to="/Contact"
                      className="green-btn"
                    >
                      Know more
                    </Link>
                  </div>
                </Fade>
                <Fade right>
                  <img
                    src={solution.image_new}
                    alt=""
                    className="solution-image mob-sol-img"
                  />
                </Fade>
              </>
            ) : (
              <>
                <Fade left>
                  <img
                    src={solution.image_new}
                    alt=""
                    className="solution-image"
                  />
                </Fade>
                <Fade right>
                  <div className="solution-content">
                    <h1 className="heading mt-[20px]">{solution.item}</h1>
                    <hr className="w-[70%] mt-[20px] text-[#E76F51]" />
                    <p className="paragraph solution-data">
                      {solution.description}
                    </p>
                    <p className="paragraph solution-data">
                      {/* {solution.description2} */}
                    </p>
                    <br />
                    <Link
                      // to={`/SingleSolution/${solution.id}`}
                      to="/Contact"
                      className="green-btn"
                    >
                      Know more
                    </Link>
                  </div>
                </Fade>
              </>
            )}
          </div>
        </Fade>
      ))}
    </div>
  );
}

export default Solutions;
