import img1 from "./media/2blog.png";
import img2 from "./media/3blog.png";
import img3 from "./media/4blog.png";
import img4 from "./media/1blog.png";
import img5 from "./media/5blog.png";
import img6 from "./media/6blog.png";

export const BlogsData = [
  {
    ID: 1,
    name: "sampath",
    image: img1,
    title: "How we treat our clients",
    body1:
      "As a company, Terces understands the importance of customer satisfaction. We believe that satisfied customers are the key to our success, and we work hard to ensure that our clients are happy with our services. At Terces, we strive to provide the highest level of customer service possible, and we believe that our commitment to customer satisfaction sets us apart from our competitors.",
    body2:
      "One of the ways that we ensure customer satisfaction is by listening to our clients. We understand that each client has unique needs and preferences, and we take the time to understand their requirements and tailor our services accordingly. Our team of dedicated professionals works closely with clients to identify their needs, provide guidance and support, and ensure that their expectations are met or exceeded.",
    body3:
      "In addition, we believe in providing timely and efficient service. We understand that our clients value their time and expect prompt and reliable service. That's why we work hard to ensure that our services are delivered on time and with the highest level of quality. We also believe in maintaining open communication with our clients throughout the process, keeping them informed of progress and any changes that may arise.",
    body4:
      "At Terces, we are committed to providing exceptional service to our clients. We understand that our success depends on their satisfaction, and we work tirelessly to ensure that our clients are happy with our services. Whether it's through our commitment to quality, timely delivery, or open communication, we are confident that our clients will be satisfied with our services.",
  },
  {
    ID: 2,
    name: "sampath",
    image: img2,
    title: "Core values and Culture",
    body1:
      "At our company, we strive to operate with a set of core values that guide our actions and shape our culture. These values are critical to our success and growth as they enable us to build trust, credibility, and respect with our clients, partners, and employees.",

    bodyhead1: "Our core values include",
    body2:
      "One of the main benefits of digital gold is that it is accessible to a wider range of investors. Unlike physical gold, which can be expensive and difficult to store, digital gold can be easily bought and sold on digital asset exchanges, making it more accessible to individual investors.",
    bodyhead2: "Instruction Loyalty ",
    body3:
      "We are committed to following and upholding the rules and regulations set by those in authority to maintain order and stability within our company and community.",
    bodyhead3: "Transparency ",
    body4:
      "We believe in openness and honesty in communication and decision-making. We prioritize transparency to build trust and credibility, both internally and externally.",
    bodyhead4: "Accountability",
    body5:
      "We hold ourselves accountable for our actions and decisions, taking responsibility for the outcomes. This core value is essential to maintaining a positive reputation and building trust with our clients and partners.",
    bodyhead5: "Commitment",
    body6:
      "We are dedicated and persistent in achieving our goals and fulfilling our responsibilities. Our commitment ensures that we are focused on our objectives and work towards achieving them with diligence and determination.",
    bodyhead6: "Integrity",
    body7:
      "We uphold moral and ethical principles and values in all our actions and decisions. Our integrity is critical to building trust, respect, and credibility with our clients, partners, and employees.",
    bodyhead7: "Honesty",
    body8:
      "We value honesty in all our communication and interactions. We believe that honesty is essential for building trust and maintaining strong relationships.",
    bodyhead8: "Sincerity",
    body9:
      "We act with genuine and honest intent in all our actions and words. Our sincerity is critical to building trust and fostering a positive culture.",

    bodyhead9: "Problem Solving",
    body10:
      "We have the ability to identify, analyze, and solve problems effectively. Our problem-solving skills enable us to overcome challenges and obstacles and achieve success.",
    bodyhead10: "Smartness",
    body11:
      "We are quick and effective thinkers, enabling us to make informed decisions and take advantage of opportunities. Our smartness is critical to achieving success and growth.",
    bodyhead11: "Persistence",
    body12:
      "We are determined to continue working towards our goals despite challenges, setbacks, and failures. Our persistence enables us to persevere through difficult times and stay focused on our objectives.",
    body13:
      "At our company, we believe that these core values are the foundation for building a positive culture that fosters collaboration, innovation, and excellence. We strive to embody these values in all our actions and decisions, and we encourage our employees, partners, and clients to do the same. By embracing these core values, we can achieve our goals, grow our business, and build lasting relationships with our clients and partners.",
  },
  {
    ID: 3,
    name: "sampath",
    image: img3,
    title: "Effeciency of the team",
    body1:
      "At Terces, we take pride in our team's efficiency, which is reflected in our exceptional customer relationship management, vendor management, and competitor analysis. We are committed to achieving success through a data-driven approach and continuous improvement, and our team works tirelessly to streamline procurement processes, conduct sales analysis, and perform ground surveys.",
    body2:
      "One of the key factors contributing to our team's efficiency is our customer relationship management (CRM). We understand that building strong relationships with our clients is critical to our success. Our team is dedicated to providing personalized services to our clients and ensuring their needs are met. We keep track of all customer interactions and use this information to improve our services continuously.",
    body3:
      "In addition to our CRM efforts, we are also efficient in vendor management. We have a comprehensive system in place for vetting and managing our suppliers and vendors. We continuously analyze their performance, ensuring that we work only with the most reliable and efficient partners.",
    body4:
      "To maintain our competitive edge, we conduct regular competitor analysis. Our team closely monitors the market trends and competitors' activities, allowing us to stay ahead of the game and adjust our strategies accordingly.",
    body5:
      "We also use data analysis to drive our decision-making process. We gather and analyze data from various sources, which allows us to make informed decisions that benefit both our company and clients. Our sales analysis enables us to understand our performance, make necessary adjustments, and provide better services to our clients.",
    body6:
      "At Terces, we also believe in streamlining procurement processes, which includes effective supplier and vendor management. By automating and simplifying these processes, we can reduce turnaround times, improve service delivery, and minimize costs.",
    body7:
      "We believe that ground surveys are a vital aspect of our business. Our team is equipped with the latest technology and tools to perform thorough ground surveys. This enables us to provide more accurate and reliable data and improve our service delivery.",
    body8:
      "Overall, our team's efficiency is reflected in our data-driven approach to decision-making, effective vendor management, streamlined procurement processes, and regular competitor and customer analysis. At Terces, we are committed to maintaining our efficiency and delivering exceptional services to our clients.",
  },
  {
    ID: 4,
    name: "sampath",
    title: "Data Analysis Training ",
    image: img4,
    body1:
      "Data analysis is becoming increasingly important in today's business landscape, and at Terces, we recognize the value of this skill set. That's why we're proud to offer comprehensive data analysis training that can help professionals improve their decision-making abilities, increase efficiency, and enhance their careers.",
    body2:
      "One of the key benefits of our data analysis training is improved decision-making. By understanding how to analyze and interpret data effectively, professionals can make more informed decisions that can have a significant impact on their business's success.",
    body3:
      "In addition to improved decision-making, our training also provides a greater understanding of customers and the business itself. Professionals who have a solid grasp of data analysis can uncover valuable insights that might otherwise go unnoticed. This information can help businesses improve their forecasting and planning capabilities, leading to increased efficiency and better communication across all departments.",
    body4:
      "Another benefit of our training is the development of analytical skills. Our course covers everything from data collection and analysis to data visualization and reporting, ensuring that participants have a thorough understanding of the entire process. This enables them to better understand the data they're working with and derive insights that can be used to drive business success.",
    body5:
      "For those looking to enhance their career opportunities, our training is an excellent choice. In addition to providing valuable skills that are in high demand, our training also prepares participants for job opportunities within Terces. Our team is always on the lookout for skilled data analysts, and our training provides participants with the skills and knowledge they need to be job-ready.",
    body6:
      " Finally, our training provides a better understanding of data, which is becoming increasingly important in today's business landscape. By understanding how to work with data, participants can become more valuable assets to their organization and open up new career opportunities.",
    body7:
      "In summary, our data analysis training offers a range of benefits, including improved decision-making, increased efficiency, and better forecasting and planning capabilities. Additionally, it provides participants with analytical skills, better understanding of data, and enhanced career opportunities. If you're looking to develop your data analysis skills, Terces is the perfect place to start.",
  },
  {
    ID: 5,
    name: "sampath",
    title: "Process and tech enhancements",
    image: img5,

    body1:
      "Terces provides process, data, and technology solutions for the food and beverage industry. Our services include POS management, digital marketing, and stock forecasting. With our expertise, we help clients optimize their operations and drive revenue growth.",
    body2:
      "Our team specializes in developing customized solutions to meet the unique needs of each client. From adding new menu items to creating new revenue streams, we work closely with our clients to identify areas for improvement and implement effective strategies.",
    body3:
      "In addition to our technology solutions, we also offer sales and marketing strategy services. We help clients develop targeted marketing campaigns and sales strategies to reach new customers and increase revenue. Our data inputs and analytics provide valuable insights into customer behavior and market trends.",

    body4:
      "Terces is committed to providing exceptional service and support to our clients. We offer our own web application for easy access to our services and a seamless user experience. With our comprehensive suite of solutions, we help clients stay competitive in the ever-changing food and beverage industry.",
  },
  {
    ID: 6,
    name: "sampath",
    image: img6,
    title: "Personalised marketing strategy",
    body1:
      "At Terces, we believe that personalised marketing is key to reaching and engaging customers. By tailoring your messaging and offers to each customer's unique interests and preferences, you can increase brand loyalty and drive sales.",
    body2:
      "Our team of experts specializes in developing personalised marketing strategies for the food and beverage industry. We use a combination of data analysis, customer segmentation, and targeted messaging to create campaigns that resonate with your audience.",
    body3:
      "One of the key benefits of personalised marketing is the ability to create more meaningful connections with customers. By showing that you understand their needs and preferences, you can build trust and establish long-term relationships.",

    body4:
      "Personalised marketing can also help you stand out in a crowded marketplace. With so many brands vying for customers' attention, a targeted approach can make your messaging more memorable and impactful.",
    body5:
      "At Terces, we use a variety of tools and techniques to develop personalised marketing strategies, including email marketing, social media advertising, and content marketing. We also use data analytics to track campaign performance and refine our approach over time.",
    body6:
      "In today's digital age, customers expect a personalised experience from the brands they interact with. By investing in a personalised marketing strategy, you can meet these expectations and build a loyal customer base that will drive growth for your business.",
  },
];
