import React, { useContext } from "react";
import FormDataContext from "./formContext";
import { stepFour } from "./step4Data";
import MainStepper from "./MainStepper";
import { useForm } from "react-hook-form";

function FormStep4() {
  let { inputsUiRendering, data, step, setStep } = useContext(FormDataContext);
  let {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: data,
  });

  return (
    <form onSubmit={handleSubmit(() => setStep(step + 1))}>
      <div className="stepsIndication">Step {step} of 6</div>
      <h1 className="stepTitle">Asses your Skills</h1>
      {inputsUiRendering(stepFour, register, control, errors)}
      <MainStepper handleSubmit={handleSubmit} isValid={isValid} />
    </form>
  );
}

export default FormStep4;
