import React from "react";
import "./LatestBlog.css";
import styled from "styled-components";

import { BlogsData } from "./BlogsData";
import SingleBlog from "./SingleBlog";
import { Link } from "react-router-dom";
import HeroOther from "../HeroOther/HeroOther";

const HeroOtherData = {
  id: 1,
  h1: "Synergetic Empowerment",
  s1: "Pioneers in Food & Retails Business",
  c1: "At Terces, we're devoted to delivering exceptional results. Our success stems from core values, excellence culture, and process-tech expertise.",
  c2: "We empower client growth via optimized operations, streamlined processes, and personalized experiences. Our values ensure client-centricity and excellence.",
};

const LatestBlog = () => {
  const handleClick = () => {
    <SingleBlog />;
  };
  return (
    <div className="wpo-blog-area">
      <HeroOther item={HeroOtherData} />
      <div className="blogs-part">
        {BlogsData.map((item) => {
          return (
            <Link to={`/Blogs/${item.ID}`} className="cardeebody ">
              <div className="bolgimage">
                <img src={item.image} alt="" class="blog-card-image" />
              </div>
              <div className="flex justify-between flex-col blog-content">
                <div className="blogcardbody">
                  <h2 className="blogheadd">{item.title}</h2>
                  <p className="blog-body">{item.body1}</p>
                </div>
                <div>
                  <div className="flex mt-[10px] p-[10px] pt-0">
                    {/* <SlArrowRight className="bg-[#0085CA] p-[8px] text-[42px] text-white" /> */}
                    <button className="green-btn">
                      <span>READ MORE</span>
                    </button>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default LatestBlog;
const Container = styled.section`
  display: flex;
  align-items: start;
  padding: 3rem 2rem;

  @media (min-width: 1910px) {
    padding: 2rem 4rem;
  }

  @media (max-width: 1200px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 900px) {
    padding: 1rem 6rem;
    flex-direction: column;
    margin: 20px 0rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 6rem;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    padding: 1rem 1rem;
  }
`;
const LeftContent = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: var(--font-description);
  width: 100%;
  margin: 30px 0px;

  @media (max-width: 1200px) {
    margin: 15px 0px;
  }
`;
export const Title = styled.h2`
  font-size: 40px;
  color: var(--font-title);
  font-weight: 500;
  line-height: 52px;

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media (max-width: 700px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
const SubWraper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;
const Subtitle = styled.p`
  color: var(--button-background);
`;
const HistoryDescription = styled(Description)`
  margin: 0;
`;
const Line = styled.p`
  width: 75px;
  height: 2px;
  background: var(--font-title);
  margin-right: 30px;
`;
const RightContent = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
  }
`;
