import Modal from "react-modal";
import "./ServicesContent.css";
import { useMediaQuery } from "react-responsive";

import { Link } from "react-router-dom";
import menu1 from "./../Media/MenuDesigns/menu1.png";
import menu2 from "./../Media/MenuDesigns/menu2.png";
import menu3 from "./../Media/MenuDesigns/menu3.png";
import menu4 from "./../Media/MenuDesigns/menu4.png";
import menu5 from "./../Media/MenuDesigns/menu5.png";
import menu6 from "./../Media/MenuDesigns/menu6.png";
import menu7 from "./../Media/MenuDesigns/menu7.png";
import kunafa from "./../Media/Items/kunafa.jpg";
import french_toast from "./../Media/Items/french_toast.webp";
import pizza from "./../Media/Items/pizza.jpg";
import rollover from "./../Media/Services/rollover.png";

import menuquote from "./../Media/Quotes/quote-menu-design.jpg";
import websitequote from "./../Media/Quotes/quote-website.jpg";
import onlinequote from "./../Media/Quotes/quote-online-marketing.jpg";
import offersquote from "./../Media/Quotes/quote-offers.jpg";
import crmquote from "./../Media/Quotes/quote-CRM.jpg";
import purchasequote from "./../Media/Quotes/quote-purchase-management.jpg";
import infraquote from "./../Media/Quotes/Quote-infrastructure.jpg";
import staffquote from "./../Media/Quotes/quote-staff.jpg";
import posquote from "./../Media/Quotes/quote-POS.jpg";

import website_brand from "./../Media/Website/brand.jpg";
import website_menu from "./../Media/Website/menu.jpg";
import website_reach from "./../Media/Website/reach.jpg";
import website_update from "./../Media/Website/update.jpg";
import website_table from "./../Media/Website/table.jpg";
import website_mobile from "./../Media/Website/mobile.jpg";
import website_domain from "./../Media/Website/domain.jpg";
import website_growth from "./../Media/Website/growth.jpg";
import website_online from "./../Media/Website/online.jpg";

import online_accounts from "./../Media/Online/accounts.png";
import online_content from "./../Media/Online/content.png";
import online_followers from "./../Media/Online/followers.png";
import online_whatsapp from "./../Media/Online/whatsapp.png";

import crm_base from "./../Media/crm/base.png";
import crm_engagement from "./../Media/crm/engagement.png";
import crm_retention from "./../Media/crm/retention.png";
import crm_updation from "./../Media/crm/updation.png";

import offers_new from "./../Media/offers/new.png";
import offers_loyal from "./../Media/offers/loyal.png";
import offers_festival from "./../Media/offers/festival.png";
import offers_occasion from "./../Media/offers/occassion.png";
import offers_best from "./../Media/offers/best_seller.png";
import offers_recommended from "./../Media/offers/recommended.png";

import infra_alternate from "./../Media/infrastructure/alternate.png";
import infra_analysis from "./../Media/infrastructure/analysis.png";
import infra_insurance from "./../Media/infrastructure/insurance.png";
import infra_interior from "./../Media/infrastructure/interior.png";
import infra_maintain from "./../Media/infrastructure/maintain.png";
import infra_track from "./../Media/infrastructure/track.png";

import purchase_consumption from "./../Media/purchase/consumption.png";
import purchase_cost from "./../Media/purchase/costeffective.png";
import purchase_monitor from "./../Media/purchase/monitor.png";
import purchase_optimize from "./../Media/purchase/optimize.png";
import purchase_order from "./../Media/purchase/order.png";
import purchase_planning from "./../Media/purchase/planning.png";

import staff_analysis from "./../Media/staff/analysis.png";
import staff_curriculum from "./../Media/staff/curricuulum.png";
import staff_equipment from "./../Media/staff/equipment_manual.png";
import staff_evaluation from "./../Media/staff/evaluation.jpg";
import staff_recipe from "./../Media/staff/recipe.png";
import staff_schedule from "./../Media/staff/schedule.jpg";

import pos_audit from "./../Media/pos/audit.png";
import pos_petpooja from "./../Media/pos/petpooja.png";
import pos_sales from "./../Media/pos/sales.jpg";
import pos_wastage from "./../Media/pos/wastage.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import React from "react";

const menuItems = [
  {
    id: 1,
    item: kunafa,
    c: "Kunafa",
  },
  {
    id: 2,
    item: pizza,
    c: "Pizza",
  },
  {
    id: 3,
    item: french_toast,
    c: "French Toast",
  },
];

const menuImages = [
  {
    id: 1,
    menu: menu1,
    c: "text1",
  },
  {
    id: 2,
    menu: menu2,
    c: "text2",
  },
  {
    id: 3,
    menu: menu3,
    c: "text3",
  },
  {
    id: 4,
    menu: menu4,
    c: "text4",
  },
  {
    id: 5,
    menu: menu5,
    c: "text5",
  },
  {
    id: 6,
    menu: menu6,
    c: "text6",
  },
  {
    id: 7,
    menu: menu7,
    c: "text7",
  },
];

const website = [
  {
    id: 1,
    bg: website_menu,
    heading: "Online Menus",
    explanation:
      "User-friendly menus to showcase dishes, making it easy for customers. Appealing and accessible designs for smooth navigation.",
  },
  {
    id: 2,
    bg: website_brand,
    heading: "Brand Reflection",
    explanation:
      "Website reflecting your unique style, mirroring ambiance, and personality. Tailored to cozy cafes, trendy bistros, or family-friendly diners.",
  },
  {
    id: 3,
    bg: website_reach,
    heading: "Reach Booster",
    explanation:
      "Having an online presence increases your chances of improving your customer base. Be found when diners are looking for a place to dine.",
  },
  {
    id: 4,
    bg: website_update,
    heading: "Easy Updates",
    explanation:
      "Effortless menu changes and special promotions. Keep customers informed without technical hassles. Always up to date.",
  },

  {
    id: 6,
    bg: website_table,
    heading: "Easy Reservations",
    explanation:
      "Simple booking and contact options for diners. Hassle-free reservation forms and contact details for potential guests.",
  },
  {
    id: 7,
    bg: website_mobile,
    heading: "Mobile-Friendly",
    explanation:
      "Optimized for mobile devices to ensure seamless browsing. A great experience on any screen size.",
  },
  {
    id: 5,
    bg: website_domain,
    heading: "Domain Care",
    explanation:
      "Domain buying, renewals, and SSL certificates for secure online experiences. Convenience and safety for your customers.",
  },
  {
    id: 8,
    bg: website_growth,
    heading: "High-Performance",
    explanation:
      "Ensure your website runs smoothly. Fast hosting to prevent customer frustration and promote menu exploration.",
  },
  {
    id: 9,
    bg: website_online,
    heading: "Ongoing Support",
    explanation:
      "Commitment beyond website launch. Continuous support to keep your digital home up and running seamlessly.",
  },
];

const online = [
  {
    id: 1,
    bg: online_accounts,
    heading: "Accounts Creation",
    explanation:
      " We kickstart your online journey by creating and optimizing social media accounts for your restaurant. From Instagram to Facebook, we ensure that your digital footprint is strong and inviting.",
  },
  {
    id: 2,
    bg: online_content,
    heading: "Engaging Content",
    explanation:
      "We post captivating reels, stories, and eye-catching visuals that not only showcase your delicious dishes but also tell your restaurant's unique story.",
  },
  {
    id: 3,
    bg: online_followers,
    heading: "Follower Growth",
    explanation:
      "We're about building a community around your restaurant. We implement strategies to organically grow your followers, ensuring that your message reaches a wider, more targeted audience.",
  },
  {
    id: 4,
    bg: online_whatsapp,
    heading: "WhatsApp Marketing",
    explanation:
      " We harness whatsapp's potential to reach your customers where they're most active. From exclusive offers to mouthwatering promotions, we make sure your WhatsApp messages are read and loved.",
  },
];

const offers = [
  {
    id: 1,
    bg: offers_best,
    heading: "Best Sellers",
    explanation:
      "We'll help you promote your best-selling dishes, making them irresistible to your customers.",
  },
  {
    id: 2,
    bg: offers_recommended,
    heading: "Recommended Delights",
    explanation:
      "We suggest dishes based on your customers' preferences, increasing their satisfaction and loyalty.",
  },
  {
    id: 3,
    bg: offers_occasion,
    heading: "Occasion-Based",
    explanation:
      "Attract party bookings and groups for special occasions with tailored promotions.",
  },
  {
    id: 4,
    bg: offers_festival,
    heading: "Festival-Themed",
    explanation:
      "Get ready for holidays and festivals with unique promotions to attract more customers.",
  },
  {
    id: 5,
    bg: offers_loyal,
    heading: "Loyalty Rewards",
    explanation:
      "Reward loyal customers with discounts and special perks, ensuring they return often.",
  },
  {
    id: 6,
    bg: offers_new,
    heading: "Welcome Incentives",
    explanation:
      "Attract first-time customers with discounts and freebies, making a great first impression.",
  },
];

const offers_benefits = [
  {
    id: 1,
    heading: "Higher Revenue",
    explanation:
      "Attract more diners, boost sales, and increase your restaurant's revenue.",
  },
  {
    id: 2,
    heading: "Loyal Customers",
    explanation:
      "Retain and grow customer loyalty with personalized promotions and rewards.",
  },
  {
    id: 3,
    heading: "Efficient Management",
    explanation:
      "Streamline promotion management, freeing time for core restaurant operations.",
  },
  {
    id: 4,
    heading: "Event Success",
    explanation:
      "Maximize profits by creating promotions tailored to special occasions and events.",
  },
  {
    id: 5,
    heading: "Market Advantage",
    explanation:
      "Stay ahead by offering themed promotions aligned with current trends and festivals.",
  },
  {
    id: 6,
    heading: "New Attraction",
    explanation:
      "Entice newcomers with discounts and freebies for a memorable first visit.",
  },
];

const crm = [
  {
    id: 1,
    bg: crm_base,
    heading: "Customer Base",
    explanation:
      "Organize and consolidate your customer data into a centralized, accessible database for better insights.",
  },
  {
    id: 2,
    bg: crm_updation,
    heading: "Database Maintenance",
    explanation:
      "Regularly update and maintain your customer database to ensure accurate and current information.",
  },
  {
    id: 3,
    bg: crm_engagement,
    heading: "Customer Engagement",
    explanation:
      "Establish a strong connection with customers through calls, texts, WhatsApp, and announcements.",
  },
  {
    id: 4,
    bg: crm_retention,
    heading: "Retention Programs",
    explanation:
      "Implement strategies and programs to retain existing customers, building long-lasting relationships and brand loyalty.",
  },
];

const crm_benefits = [
  {
    id: 1,
    heading: "Customer Insights",
    explanation:
      "Gain valuable data for personalized services and menu enhancements, increasing customer satisfaction.",
  },
  {
    id: 2,
    heading: "Efficient Marketing",
    explanation:
      "Execute targeted marketing campaigns, improving ROI and customer engagement effectively.",
  },
  {
    id: 3,
    heading: "Repeat Business",
    explanation:
      "Foster customer loyalty, ensuring patrons return for memorable dining experiences.",
  },
  {
    id: 4,
    heading: "Data-Driven Growth",
    explanation:
      "Leverage data to make informed decisions, driving restaurant growth and profitability.",
  },
];

const purchase = [
  {
    id: 1,
    bg: purchase_consumption,
    heading: "Consumption Analysis",
    explanation:
      "We assist by thoroughly examining item usage, allowing informed, cost-effective kitchen item procurement.",
  },
  {
    id: 2,
    bg: purchase_planning,
    heading: "Purchase Planning",
    explanation:
      "Our service streamlines order scheduling based on consumption patterns, ensuring efficient and economical purchases.",
  },
  {
    id: 3,
    bg: purchase_order,
    heading: "Purchase Order",
    explanation:
      "Conveniently generate standardized orders, simplifying the entire procurement process, and ensuring precision in your kitchen's supply needs.",
  },
  {
    id: 4,
    bg: purchase_monitor,
    heading: "Stock Monitoring",
    explanation:
      "Stay updated in real-time with stock levels, preventing shortages and waste while promoting smooth kitchen operations.",
  },
  {
    id: 5,
    bg: purchase_optimize,
    heading: "Stock Optimization",
    explanation:
      "We maintain optimal inventory levels, saving on costs, and improving kitchen efficiency.",
  },
  {
    id: 6,
    bg: purchase_cost,
    heading: "Cost Efficiency",
    explanation:
      "Our expertise identifies budget-friendly, quality products, ensuring significant savings without compromising on your kitchen's standards.",
  },
];

const purchse_benefits = [
  {
    id: 1,
    heading: "Cost Savings",
    explanation:
      "Efficient inventory management minimizes waste, reducing overall operational costs.",
  },
  {
    id: 2,
    heading: "Streamlined Procurement",
    explanation: "Simplify ordering, saving time and ensuring timely supply.",
  },
  {
    id: 3,
    heading: "Reduced Stockouts",
    explanation:
      "Real-time monitoring prevents ingredient shortages, maintaining consistent service.",
  },
  {
    id: 4,
    heading: "Efficiency Boost",
    explanation:
      "Optimal inventory levels boost kitchen workflow and service speed.",
  },
  {
    id: 5,
    heading: "Smart Choices",
    explanation: "Insights improve menu planning and purchasing choices.",
  },
  {
    id: 6,
    heading: "Quality Assurance",
    explanation:
      "Identify cost-effective, high-quality products for improved customer satisfaction.",
  },
];

const infra = [
  {
    id: 1,
    bg: infra_analysis,
    heading: "Machinery Analysis",
    explanation:
      "We help you in understanding your kitchen equipment with our expert guidance. We document the machinery list for better clarity & maintenance.",
  },
  {
    id: 2,
    bg: infra_maintain,
    heading: "Maintenance Plan",
    explanation:
      "We plan for the maintenance and servicing of your kitchen machinery so that they work for a long time without unexpected breakdowns.",
  },
  {
    id: 3,
    bg: infra_track,
    heading: "Alert Tracking",
    explanation:
      "We send timely alerts to you for the servicing of the machinery. And also keep track of the maintenance to ensure it is not missed.",
  },
  {
    id: 4,
    bg: infra_alternate,
    heading: "Alternative Solutions",
    explanation:
      "We help you to find economical and best alternative solutions for your kitchen machinery in the time unforeseen breakdown of old machines.",
  },
  {
    id: 5,
    bg: infra_insurance,
    heading: "Trusted Insurance",
    explanation:
      "We will provide a list of trusted insurance partners to cover all your kitchen and interior items. Your restaurant infrastructure will be taken care of.",
  },
  {
    id: 6,
    bg: infra_interior,
    heading: "Economic Interiors",
    explanation:
      "We suggest you the best, cost-effective, eye-catching and engaging interiors. The well designed interior will attract more customers.",
  },
];

const infra_benefits = [
  {
    id: 1,
    heading: "Efficient Operations",
    explanation:
      "Streamline kitchen processes, reduce downtime, and increase productivity with machinery analysis.",
  },
  {
    id: 2,
    heading: "Preventive Maintenance",
    explanation:
      "Avoid costly breakdowns and ensure machinery longevity through regular servicing.",
  },
  {
    id: 3,
    heading: "Timely Maintenance",
    explanation:
      "Stay informed about servicing needs and track progress for peace of mind.",
  },
  {
    id: 4,
    heading: "Cost Savings",
    explanation:
      "Discover budget-friendly alternatives to costly kitchen machinery replacements.",
  },
  {
    id: 5,
    heading: "Risk Mitigation",
    explanation:
      "Protect your investments with reliable insurance for machinery and interior assets.",
  },
  {
    id: 6,
    heading: "Atmosphere Enhancement",
    explanation:
      "Create an inviting space and elevate customer experience with economic interior suggestions.",
  },
];

const staff = [
  {
    id: 1,
    bg: staff_analysis,
    heading: "Skill Analysis",
    explanation:
      "We analyze the current skill level and need skill level of the existing employees of your restaurant.",
  },
  {
    id: 2,
    bg: staff_recipe,
    heading: "Recipe Manuals",
    explanation:
      "We prepare a recipes manual of your menu to be understood by anyone irrespective of their education levels.",
  },
  {
    id: 3,
    bg: staff_equipment,
    heading: "Equipment Manuals",
    explanation:
      "We create your equipments manual in an easily understandable manner to be used by your staff for efficient usage.",
  },
  {
    id: 4,
    bg: staff_curriculum,
    heading: "Custom Curriculum",
    explanation:
      "We plan a customized training curriculum for your staff that includes knowledge related to recipes, equipment, service, etc.",
  },
  {
    id: 5,
    bg: staff_schedule,
    heading: "Training Scheduling",
    explanation:
      "We schedule a customized training program that best suits to your needs, and also focus on skill upgradation programs.",
  },
  {
    id: 6,
    bg: staff_evaluation,
    heading: "Evaluation ",
    explanation:
      "After completion of the staff training, we carefully evaluate them and make sure they got the skills needed.",
  },
];

const staff_benefits = [
  {
    id: 1,
    heading: "Improved Efficiency",
    explanation:
      "Streamlined operations for quicker service and reduced wait times.",
  },
  {
    id: 2,
    heading: "Consistency in Quality",
    explanation: "Ensure every dish meets your high standards, every time.",
  },
  {
    id: 3,
    heading: "Customer Satisfaction",
    explanation:
      "Satisfied patrons return, boosting loyalty and profitability.",
  },
  {
    id: 4,
    heading: "Skilled Workforce",
    explanation:
      "Staff gains expertise, reducing errors and enhancing productivity.",
  },
  {
    id: 5,
    heading: "Customized Training",
    explanation: "Tailored programs align with your restaurant's unique needs.",
  },
  {
    id: 6,
    heading: "Effective Training",
    explanation: "Minimize disruptions while enhancing staff capabilities.",
  },
  {
    id: 7,
    heading: "Data-Driven Insights",
    explanation:
      "Measure performance and make informed decisions for continuous improvement.",
  },
  {
    id: 8,
    heading: "Cost-Efficiency",
    explanation:
      "Reduced waste and errors lead to cost savings and increased profits.",
  },
  {
    id: 9,
    heading: "Competitive Edge",
    explanation:
      "Stand out in a crowded market with top-notch staff and service.",
  },
  {
    id: 10,
    heading: "Team Unity",
    explanation:
      "Well-trained staff works together harmoniously, boosting overall morale.",
  },
];

const pos = [
  {
    id: 1,
    bg: pos_petpooja,
    heading: "Inventory Control",
    explanation:
      "We assist you in integrating an inventory management system like petpooja that helps in various analysis solutions",
  },
  {
    id: 2,
    bg: pos_sales,
    heading: "Sales Insights",
    explanation:
      "We help you to get a clear picture of what's selling well and identify opportunities to boost revenue",
  },
  {
    id: 3,
    bg: pos_wastage,
    heading: "Waste Reduction",
    explanation:
      "We assist you in pinpointing where waste occurs and manage raw material usage for cost-effective operations",
  },
  {
    id: 4,
    bg: pos_audit,
    heading: "Regular audits",
    explanation:
      "We make sure to protect your profits with thorough audits that ensure your business stays in compliance and thrives.",
  },
];

const pos_benefits = [
  {
    id: 1,
    heading: "Enhanced Efficiency",
    explanation:
      "Streamlined operations for quicker service and reduced operational overhead.",
  },
  {
    id: 2,
    heading: "Informed Decision-Making",
    explanation:
      "Data-driven insights lead to strategic choices for growth and profitability.",
  },
  {
    id: 3,
    heading: "Cost Savings",
    explanation:
      "Reduced wastage and precise inventory control result in lower expenses.",
  },
  {
    id: 4,
    heading: "Increased Profitability",
    explanation:
      "Boost sales, reduce waste, and optimize inventory to maximize revenue.",
  },
  {
    id: 5,
    heading: "Compliance Assurance",
    explanation:
      "Stay audit-ready, ensuring legal and financial compliance for peace of mind.",
  },

  {
    id: 7,
    heading: "Real-Time Visibility",
    explanation:
      "Stay updated with inventory, sales, and operational data for agile decision-making.",
  },
  {
    id: 8,
    heading: "Reduced Errors",
    explanation:
      "Minimize costly mistakes in inventory management, sales tracking, and waste control.",
  },
  {
    id: 9,
    heading: "Resource Optimization",
    explanation:
      "Efficiently allocate resources with precise analysis of wastage and consumption.",
  },
  {
    id: 10,
    heading: "Business Growth",
    explanation:
      "With improved operations, free up time and resources for expansion and innovation.",
  },
];

export function MenuManagement() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="">
      <h1 className="service-title">Customized Menu Designs</h1>
      <p className="paragraph">
        Your menu is the first impression of your brand. If designed properly,
        it can help you promote your business, attract more customers and make
        more money. Terces's Menu Design, offers you access to a creative team
        with expertise in menu design, so you can activate your most important
        marketing tool.
      </p>
      <br />
      <h1 className="heading">Our designs speak for themselves</h1>
      <div className="menu-slider-div">
        <Slider {...settings}>
          {menuImages.map((menuItem, index) => (
            <div key={menuItem.id} onClick={() => openModal(index)}>
              <img src={menuItem.menu} alt="" className="menu-design-img" />
            </div>
          ))}
        </Slider>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="menu-design-modal">
        <Slider {...settings2} initialSlide={selectedImageIndex}>
          {menuImages.map((menuItem, index) => (
            <div key={menuItem.id}>
              <img
                src={menuItem.menu}
                alt=""
                className="menu-design-img-modal"
              />
            </div>
          ))}
        </Slider>
        <button onClick={closeModal} className="menu-design-modal-close-btn">
          X
        </button>
      </Modal>
      <br />
      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get a menu design" : "Get a menu design for my restaurant"}
      </Link>
      <br />
      <br />
      <p className="paragraph">
        We carefully look at your current menu and pick out your best-selling
        items. Then, we create a new menu that's more attractive and easy to
        understand for your customers. This change benefits both you and your
        customers, making it a win-win for everyone.{" "}
      </p>
      <br />
      <hr />
      <h1 className="service-title">New Items Addition</h1>
      <p className="paragraph">
        New item introduction is always one of the better ways to increase
        revenue of any food based enterprise. It helps in redefining who you
        are, make yourself more presentable to your audience, more engaging with
        the current trend.
      </p>
      <br />
      <p className="paragraph">
        We initiate it with assessing the local food market's demand and your
        current infrastructure, and the skillset of your existing staff. Then,
        we recommend the most strategic menu items to make your menu more
        appealing.
      </p>
      <br />
      <h1 className="heading">New Items Added @Rollover</h1>
      <div className="new-items-div">
        {menuItems.map((menuItem, index) => (
          <img
            src={menuItem.item}
            alt=""
            className="menu-item-img"
            key={menuItem.id}
          />
        ))}
      </div>
      <br />
      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Add a new item to my menu"}
      </Link>
      <br />
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <h1 className="heading mt-[20px] mb-[10px]">Customers Satisfaction</h1>
      <p className="paragraph">
        A well designed menu helps the customers to choose with ease from a
        variety of items you offer. It enables them to spend as less time as
        possible to select.
      </p>
      <h1 className="heading mt-[20px] mb-[10px]">Increased Revenue</h1>
      <p className="paragraph">
        Well designed menu with new trendy items attracts more customers to your
        restaurant making more money for you and satisfaction to your customers.
      </p>
      <h1 className="heading mt-[20px] mb-[10px]">Elevated Brand Value</h1>
      <p className="paragraph">
        New and successful items that stand out from the rest makes your brand
        one of a kind in your city.
      </p>
      <br />
      <Link to="/Contact" className="green-btn">
        Get in Touch
      </Link>
      <br />
      <br />
      <br />
      <img src={menuquote} alt="" />
    </div>
  );
}

export function WebsiteDesign() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">A Restaurant's Online Home!</h1>
      <p className="paragraph">
        In today's fast-paced world, having a strong online presence is crucial
        for the success of any restaurant. That's where we come in – we
        specialize in creating and hosting websites that not only look great but
        are also easy to understand and manage, even for those who might not be
        tech-savvy.
      </p>
      <br />
      <h1 className="benefits-title">
        Why Do I need a website for my restaurant?
      </h1>
      <br />
      <p className="paragraph">
        Your restaurant's website is more than just a virtual menu – it's your
        digital front door, open 24/7, welcoming hungry customers from near and
        far. Here's why you need a dedicated restaurant website:
      </p>
      <div className="website-benefits-div">
        {website.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        Owning a restaurant is about delighting customers with your culinary
        expertise. Let us take care of delighting them online. We make it
        simple, accessible, and tailored to your unique needs.
      </p>
      <br />
      <p className="paragraph">
        Give your restaurant the digital boost it deserves. Contact us today to
        discuss how we can help you create a stunning website that brings more
        diners through your door, both physical and virtual.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Get a website for my restaurant"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">Look @Our Work</h1>
      <br />
      <a href="https://rolloverwarangal.com/" target="_blank">
        <img src={rollover} alt="" />
      </a>
      <br />
      <br />
      <img src={websitequote} alt="" />
    </div>
  );
}

export function OnlineMarketing() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Boosting Restaurant Visibility</h1>
      <p className="paragraph">
        In the ever-evolving world of digital marketing, we're here to ensure
        that your restaurant not only survives but thrives. We offer a
        comprehensive range of social media services designed to boost your
        online visibility and keep your customers engaged.
      </p>
      <br />
      <h1 className="benefits-title">All in one social media services</h1>
      <br />
      <p className="paragraph">
        Our suite of services is designed to empower your restaurant with a
        compelling online presence, drive customer engagement, and boost your
        brand's visibility. We are your trusted partner in the world of digital
        marketing for restaurants. Here's how we can enhance your restaurant's
        online journey
      </p>
      <div className="website-benefits-div">
        {online.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        In an industry where customer preferences and trends change rapidly,
        adapting and excelling in the digital realm is vital. At Terces, we're
        committed to helping your restaurant thrive by leveraging the power of
        social media and WhatsApp marketing. We understand the nuances of the
        restaurant business and tailor our services to suit your unique needs.
      </p>
      <br />
      <p className="paragraph">
        With our comprehensive services, we not only create an impressive
        digital presence but also nurture your restaurant's online community.
        We're more than just marketers; we're your partners in growth, here to
        ensure that your restaurant reaches new heights of success. Join hands
        with us, and together, we'll create a digital dining experience that
        keeps your customers coming back for more.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "" : "Begin my online engagement"}
      </Link>
      <br />
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <ul className="paragraph benefits-ul">
        <li className="online-benefit-item">
          <h1 className="heading">Enhanced Online Presence</h1>
          <p className="paragraph mt-[5px]">
            Attract a wider and more diverse audience of potential customers.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Increased Customer Engagement</h1>
          <p className="paragraph mt-[5px]">
            Create a personal connection with customers by sharing the
            restaurant's unique story.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Community Building</h1>
          <p className="paragraph mt-[5px]">
            Encourage customer interaction, reviews, and recommendations,
            fostering a sense of belonging.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Elevated Follower Growth</h1>
          <p className="paragraph mt-[5px]">
            Reach a larger, more targeted audience that's genuinely interested
            in your restaurant.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Risen Brand Visibility</h1>
          <p className="paragraph mt-[5px]">
            Improve the visibility of your restaurant in local and online
            searches.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Competitive Advantage</h1>
          <p className="paragraph mt-[5px]">
            Showcase your restaurant's innovative and customer-centric approach.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Driven Foot Traffic</h1>
          <p className="paragraph mt-[5px]">
            Increase reservations, orders, and in-house visits through effective
            marketing.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Cost-Effective Marketing</h1>
          <p className="paragraph mt-[5px]">
            Optimize marketing efforts for a cost-effective return on
            investment.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Long-Term Relationships</h1>
          <p className="paragraph mt-[5px]">
            Encourage repeat business and referrals, leading to sustained
            growth.
          </p>
        </li>
        <li className="online-benefit-item">
          <h1 className="heading">Customized Marketing Strategies</h1>
          <p className="paragraph mt-[5px]">
            Tailored marketing strategies to the specific needs and goals of
            your restaurant.
          </p>
        </li>
      </ul>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={onlinequote} alt="" />
    </div>
  );
}

export function OffersPromotion() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Restaurant Promotions Made Easy</h1>
      <p className="paragraph">
        In the fiercely competitive restaurant industry, staying ahead requires
        more than just delicious food – it demands a strategic approach to
        promotions. At Terces, we've got your back with a suite of restaurant
        promotion services that are as simple as they are effective.
      </p>
      <br />
      <h1 className="benefits-title">Offers & Promotions for everyone</h1>
      <br />
      <p className="paragraph">
        We understand that you're focused on the art of food, so we've
        simplified the science of increasing foot traffic and sales. With our
        six types of offers and promotions, you can easily draw in customers and
        keep them coming back for more. Let's explore how these services can
        work for your restaurant.
      </p>
      <div className="website-benefits-div">
        {offers.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        In the world of dining, customer satisfaction is paramount. Terces is
        here to help you not only satisfy but also delight your patrons with
        irresistible promotions. From showcasing your bestsellers to creating
        tailored experiences for your customers, we make it all effortless.
      </p>
      <br />
      <p className="paragraph">
        Embrace the power of these offers and promotions to enhance your
        restaurant's success. With [Your Company Name], boosting sales and
        building customer loyalty is not just within reach – it's a promise.
        Discover a world of possibilities for your restaurant today.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        Boost my Revenue
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {offers_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={offersquote} alt="" />
    </div>
  );
}

export function CRM() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Restaurant CRM Solutions</h1>
      <p className="paragraph">
        In the competitive world of dining, connecting with customers is
        paramount. Our Restaurant CRM Solutions simplify the process, offering
        key services to help restaurant owners effectively manage and grow their
        customer base.
      </p>
      <br />
      <h1 className="benefits-title">Customer Relationships Simplified</h1>
      <br />
      <p className="paragraph">
        From organizing customer data to keeping it up-to-date, maintaining
        active connections, and retaining loyal customers, we've got you
        covered.
      </p>
      <div className="website-benefits-div">
        {crm.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        With our comprehensive Restaurant CRM Solutions, you can harness the
        power of customer data. You'll ensure accurate and updated information,
        engage with patrons effectively, and establish lasting customer loyalty.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Boost my customer base"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {crm_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={crmquote} alt="" />
    </div>
  );
}

export function PurchaseManagement() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Stock Management Solutions</h1>
      <p className="paragraph">
        Efficient management of kitchen inventory is the backbone of a
        successful restaurant. Our suite of six essential services empowers
        restaurant owners to take control of their inventory, reduce costs, and
        enhance operations.
      </p>
      <br />
      <h1 className="benefits-title">Streamline, Optimize, and Save</h1>
      <br />
      <p className="paragraph">
        From analyzing consumption patterns to ensuring cost-effective
        procurement, we've got you covered. In this comprehensive guide, we'll
        delve into each of our services, showing you how they can revolutionize
        your kitchen operations and your bottom line.
      </p>
      <div className="website-benefits-div">
        {purchase.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        By implementing our Kitchen Inventory Mastery services, you can
        transform your restaurant's inventory management. From understanding
        consumption patterns to optimizing stock levels and saving on costs,
        these services are designed to make your restaurant more efficient,
        cost-effective, and competitive.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Reduce my purchase cost"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {purchse_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={purchasequote} alt="" />
    </div>
  );
}

export function InfraHandling() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Taking care of Infrastructure</h1>
      <p className="paragraph">
        Welcome to Restaurant Infrastructure Solutions, where we understand the
        critical importance of a well-structured infrastructure for your
        restaurant's success. Our range of services is tailored to enhance the
        core elements of your establishment, ensuring it runs seamlessly.
      </p>
      <br />
      <h1 className="benefits-title">Optimizing Your Restaurant's Potential</h1>
      <br />
      <p className="paragraph">
        From the heart of your kitchen to the ambiance that sets the mood, our
        infrastructure services guarantee that your restaurant functions at its
        best. Let's explore how our solutions can fortify your restaurant's
        foundation.
      </p>
      <div className="website-benefits-div">
        {infra.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        With our expertise, you can keep your kitchen machinery in top shape,
        explore alternatives, and even design an inviting interior – all while
        staying cost-conscious. Let us be your trusted partner on this journey,
        so you can focus on what matters most: delighting your customers with
        exceptional culinary experiences.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Analysis my restaurant infrastructure"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {infra_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={infraquote} alt="" />
    </div>
  );
}

export function StaffTraining() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Staff Training Simplified</h1>
      <p className="paragraph">
        In the dynamic world of dining, success hinges on skilled staff and
        top-notch service. Our Restaurant Training Solutions are here to empower
        your restaurant to excel.
      </p>
      <br />
      <h1 className="benefits-title">Elevate Your Staff's Expertise</h1>
      <br />
      <p className="paragraph">
        Explore our tailored suite of services, covering everything from skill
        analysis to custom training programs, designed to elevate your team and
        enhance your restaurant's reputation. Let's embark on a journey to
        culinary excellence together.
      </p>
      <div className="website-benefits-div">
        {staff.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        From analyzing current skill levels to crafting customized training
        programs, we've got you covered. Boost your staff's capabilities,
        streamline operations, and watch your business flourish. Elevate your
        restaurant's excellence today!
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Upskill my restaurant staff"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {staff_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={staffquote} alt="" />
    </div>
  );
}

export function POS() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Analysis Simplification - POS</h1>
      <p className="paragraph">
        In the digital age, your restaurant's success depends on more than just
        mouthwatering dishes. It hinges on efficient management and data-driven
        decisions. Our POS Integration Solutions are tailored to simplify your
        restaurant operations.
      </p>
      <br />
      <h1 className="benefits-title">Boosting Restaurant Performance</h1>
      <br />
      <p className="paragraph">
        From optimizing inventory control to providing valuable sales insights,
        we're here to help you reduce waste, maximize revenue, and secure your
        financial health. Let's delve into the details of how our services can
        revolutionize the way you manage your restaurant, enabling you to stay
        ahead of the competition.
      </p>
      <div className="website-benefits-div">
        {pos.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        Embrace the future of restaurant management with our POS Integration
        Solutions. By effortlessly coordinating inventory, sales analysis, waste
        reduction, and audits, we empower you to achieve your culinary vision
        while maintaining financial stability. Elevate your restaurant's
        performance today and ensure it continues to thrive in an ever-evolving
        industry.
      </p>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Integrate POS for my restaurant"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {pos_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={posquote} alt="" />
    </div>
  );
}
