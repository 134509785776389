import React, { useEffect, useState, useContext } from "react";
import { GoCheck } from "react-icons/go";
import { LangContext } from "../../../../App";
import "./modalMessageSend.css";

const ModalMessageSend = () => {
  const { langEng } = useContext(LangContext);
  const [loading, setLoading] = useState(false); // Fix destructuring here

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true); // After 5 seconds, set loading to false
    }, 5000);

    return () => clearTimeout(timeout); // Cleanup the timeout on unmount
  }, []);

  return loading ? (
    ""
  ) : (
    <div className="modal-message-send flex mt-[20px]">
      <GoCheck size={30} className="message-icon1" />
      <span className="mt-[5px] ml-[5px]">
        {langEng
          ? "Message successfully sent."
          : "Сообщение успешно отправлено."}
      </span>
    </div>
  );
};

export default ModalMessageSend;
