import Accountability from "./Media/Accountabilty2.jpg";

export const CoreValuesData = [
  {
    id: 2,
    image: Accountability,
    name: "Accountability",
    color: "red",
    subtitle: "Owning Actions, Uplifting Your Confidence",
    description:
      "We take full ownership of our actions, decisions, and their outcomes.",
    color: "#ff9999",
    desc1:
      "We are passionate about following through on our promises and ensuring precision in everything we do. Our unwavering loyalty is evident in our dedication to building lasting relationships and trust with our clients.",
  },
  {
    id: 4,
    image: Accountability,
    name: "Transparency",
    color: "Black",
    subtitle: "Clear and Genuine in All We Do",
    description:
      "We are open, genuine, and crystal-clear in our approach to craftsmanship.",
    desc1:
      "Embrace our commitment to transparency as a symbol of our authenticity and dedication to openness in all our interactions. We believe in delivering products and solutions that reflect our core value of transparency.",
  },
  {
    id: 6,
    image: Accountability,
    name: "Commitment",
    color: "Blue",
    subtitle: "Dedicated to Excellence, Devoted to You",
    description: "At Terces, we are dedicated to excellence in all that we do.",
    desc1:
      "Commitment is the driving force behind every project we undertake. We go above and beyond to exceed expectations, providing services and solutions that reflect your unique needs and our commitment to delivering the best.",
  },
  {
    id: 7,
    image: Accountability,
    name: "Integrity",
    color: "#ff9999",
    subtitle: "Upholding with Pride, Ethical at Heart",
    description:
      "Make a statement with our unwavering commitment to integrity at Terces.",
    desc1:
      "Integrity is woven into the fabric of our brand. We believe in doing the right thing, even when no one is watching. Our commitment to integrity is a reflection of our dedication to making ethical choices and being true to ourselves and our clients.",
  },
  {
    id: 8,
    image: Accountability,
    color: "Orange",
    name: "Persistence",
    subtitle: "Enduring Challenges, Creating Value",
    description:
      "Our persistence drives us to create enduring value for our clients.",
    desc1:
      "Persistence is the key to creating lasting value, and it's at the core of our approach at Terces. We continually explore innovative solutions and use our expertise to tackle challenges head-on, ensuring that we provide value that stands the test of time.",
  },
];

export const CoreValuesData2 = [
  // {
  //   id: 1,
  //   image: instruction,
  //   name: "Instruction Loyalty",
  //   subtitle: "Guided by Instructions, Loyal in Service",
  //   description: "Our selection of rings features expert craftsmanship.",
  //   desc1:
  //     "At Instruction Loyalty, we believe in following instructions diligently, ensuring we meet our customers' needs with precision. Our unwavering loyalty is evident in every aspect of our service, building lasting relationships and trust. As jewelry enthusiasts ourselves, we understand the significance of each piece, and our commitment to quality shines through our designs.",
  //   desc2:
  //     "Embrace our finely crafted rings that reflect the embodiment of loyalty, both in style and essence. With us, you can confidently choose timeless pieces that resonate with your unique journey and values.",
  // },
  {
    id: 2,
    image: Accountability,
    name: "Accountability",
    subtitle: "Owning Actions, Uplifting Your Confidence",
    description:
      "We take full ownership of our actions, decisions, and their outcomes.",
    desc1:
      "Accountability is the cornerstone of our brand. We take full ownership of our actions, decisions, and their outcomes, ensuring unparalleled service for our customers. With an exquisite collection of earrings, we aspire to uplift your style and confidence. Our passion for excellence drives us to be accountable for every detail, from design to delivery.",
    desc2:
      "As a team of responsible artisans, we guarantee that our earrings will not only adorn your ears but also symbolize a mark of our commitment to accountability, delivering timeless beauty and unmatched quality.",
  },
  // {
  //   id: 3,
  //   image: Honesty,
  //   name: "Honesty",
  //   subtitle: "Embracing Truth, Building Trust",
  //   description: "Make a statement with our exquisite pendants.",
  //   desc1:
  //     "At Honesty, we believe that honesty is the foundation of genuine connections. Our exquisite pendants are more than just adornments; they carry the essence of sincerity and transparency. As purveyors of truth, we conduct our business with integrity, delivering authentic products and experiences. Embrace our meticulously crafted pendants that symbolize not only your style but also the timeless values of honesty and trust.",
  //   desc2:
  //     "Let the world see your commitment to transparency and truthfulness through the elegance of our finely designed pendants, forged with love and authenticity.",
  // },
  {
    id: 4,
    image: Accountability,
    name: "Transparency",
    subtitle: "Clear and Genuine in All We Do",
    description:
      "We are open, genuine, and crystal-clear in our approach to craftsmanship.",
    desc1:
      "Transparency is at the heart of our brand, reflected in our stunning collection of bracelets. We are open, genuine, and crystal-clear in our approach to craftsmanship and customer service. Our bracelets not only enhance your style but also serve as a testament to our commitment to authenticity. Embrace our bracelets as a symbol of transparency, an accessory that represents the beauty of openness, and a constant reminder of the honesty we stand for.",
    desc2:
      "With a dedication to quality materials and designs, we ensure our bracelets resonate with your innermost values, reflecting the true essence of who you are.",
  },
  // {
  //   id: 5,
  //   image: Sincerity,
  //   name: "Sincerity",
  //   subtitle: "Embodying Truth, Cherishing Moments",
  //   description: "Our selection of rings features expert craftsmanship.",
  //   desc1:
  //     "At Sincerity, we pour our hearts into creating jewelry that exudes sincerity. Our rings are crafted with expert skill and attention to detail, symbolizing the essence of authenticity. As advocates of sincerity, we believe in building relationships based on trust and openness. Our rings represent a deeper connection, embracing your unique journey with each piece.",
  //   desc2:
  //     "With us, you'll find rings that speak the language of sincerity, reminding you to be true to yourself and cherish the moments that make life truly meaningful.",
  // },
  {
    id: 6,
    image: Accountability,
    name: "Commitment",
    subtitle: "Dedicated to Excellence, Devoted to You",
    description: "Elevate any outfit with our stunning collection of earrings.",
    desc1:
      "Commitment is the driving force behind every piece we create. Our stunning collection of earrings represents our unwavering dedication to excellence. As artisans committed to perfection, we ensure that every earring embodies our core values. With a deep passion for craftsmanship, we strive to exceed expectations, providing you with earrings that reflect your unique style and our commitment to deliver the best.",
    desc2:
      "Embrace our earrings as a testament to your own commitment to elegance and excellence, and join us in celebrating the artistry of devotion.",
  },
  {
    id: 7,
    image: Accountability,
    name: "Integrity",
    subtitle: "Upholding with Pride, Ethical at Heart",
    description: "Make a statement with our exquisite pendants.",
    desc1:
      "Integrity is woven into the fabric of our brand. Our exquisite pendants epitomize the strength of character and ethical principles we uphold. We believe in doing the right thing, even when no one is watching. Our pendants serve as emblems of integrity, a reflection of your commitment to making ethical choices and being true to yourself.",
    desc2:
      "With us, you'll find finely designed pendants that resonate with your values, symbolizing the enduring beauty of integrity that shines through every facet.",
  },
  {
    id: 8,
    image: Accountability,
    name: "Persistence",
    subtitle: "Enduring Challenges, Creating Value",
    description: "Our collection of bracelets offers both style and substance.",
    desc1:
      "Persistence is the key to creating enduring value, and it's at the heart of our collection of bracelets. We persistently explore innovative designs and use the finest materials to craft bracelets that stand the test of time. Our bracelets not only enhance your style but also embody the essence of persistence.",
    desc2:
      "Embrace our bracelets as a symbol of resilience, a reminder that every challenge is an opportunity for growth. With us, you'll discover bracelets that celebrate your journey, echoing the strength and determination you carry within.",
  },
  // {
  //   id: 9,
  //   image: ProblemSolving,
  //   name: "Problem Solving",
  //   subtitle: "Innovate and Resolve, Solutions in Style",
  //   description: "Our selection of rings features expert craftsmanship.",
  //   desc1:
  //     "At Problem Solving, we see challenges as opportunities for innovation. Our selection of rings represents our dedication to finding creative solutions. Each ring is a work of art, combining expert craftsmanship and thoughtful design. We approach every problem with a fresh perspective, ensuring that our rings embody the spirit of innovation.",
  //   desc2:
  //     "Embrace our rings as a symbol of your problem-solving mindset, a reminder of the infinite possibilities that arise when you embrace challenges with determination and ingenuity.",
  // },
  // {
  //   id: 10,
  //   image: Smartness,
  //   name: "Smartness",
  //   subtitle: "Embrace Ingenuity, Shine Smartly",
  //   description: "Elevate any outfit with our stunning collection of earrings.",
  //   desc1:
  //     "At Smartness, we celebrate the brilliance of ingenuity. Our stunning collection of earrings showcases designs that are as smart as they are stylish. With a focus on smart strategies and innovative thinking, we bring you earrings that stand out from the crowd. Our passion for creativity drives us to deliver exceptional results, and our earrings embody the essence of smartness.",
  //   desc2:
  //     "Embrace our earrings as a symbol of your own ingenuity, reflecting your unique approach to life and style.",
  // },
];
