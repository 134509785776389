export let stepTwo = [
  {
    type: "dropDown",
    fieldLabel: "Graduation",
    stateName: "graduation",
    options: ["", "Degree", "PG", "Btech", "Mtech"],
  },

  {
    type: "text",
    fieldLabel: "What department are you in?",
    stateName: "department",
    pattern: /[a-z]/i,
    patternErrMsg: "Wirte a valid input",
  },
  {
    type: "dropDown",
    fieldLabel: "What semester are you in?",
    stateName: "semester",
    options: ["", 1, 2, 3, 4, 5, 6, 7, 8, "Completed"],
  },
  {
    type: "text",
    fieldLabel: "What is your total GPA?",
    stateName: "gpa",
    pattern: /^[0-9]$|^10(\.[0]{1,2})?$|^[0-9](\.[0-9]{1,2})?$/,
    patternErrMsg: "GPA field should contain a numerical value from 0 to 10",
  },
  {
    type: "text",
    fieldLabel: "Where do you completed your SSC?",
    stateName: "tenth",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
  {
    type: "text",
    fieldLabel: "Where do you completed your Secondary Eduction?",
    stateName: "inter",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
  {
    type: "text",
    fieldLabel: "Where do you completed your Graduation?",
    stateName: "graduationplace",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
  {
    type: "radioButton",
    fieldLabel: "Education after graduation?",
    stateName: "Aftergraduation",
    options: ["Yes", "No"],
  },

  {
    type: "ConditionalText",
    fieldLabel: "Education after graduation",
    stateName: "Aftereducationdetailswhich",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
  {
    type: "ConditionalText",
    fieldLabel: "Where do you completed your further studies?",
    stateName: "Aftereducationdetailswhere",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
  {
    type: "radioButton",
    fieldLabel: "Did you done any project in your semesters?",
    stateName: "civilWork",
    options: ["Yes", "No"],
  },
  {
    type: "ConditionalText",
    fieldLabel: "Explain about your project briefly",
    stateName: "civilworkDetails",
    pattern: /[a-z]/i,
    patternErrMsg: "Write a valid input",
  },
];
