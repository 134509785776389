import React from "react";
import "./WhoWeAre.css";
import { Link } from "react-router-dom";
import "./../CareerCard/CareerCard.css";
import team from "./whoweare.png";

function WhoWeAre() {
  return (
    <div className="who-we-are-part">
      <div className="who-we-are-left">
        <h1 className="main-title who-we-are-title !text-white">Who We Are</h1>
        <hr className="my-[30px] bg-[white] h-[5px] w-[50px]" />
        <p className="paragraph-white !text-white">
          Our identity revolves around empowering food and retail enterprises.
          Our experienced team, well-versed in the industry, creates specialized
          solutions that go beyond the norm. We tailor strategies to optimize
          operations and refine brand identities, leveraging digital expertise
          to deploy data-driven solutions for success in the ever-evolving
          realms of food and retail.
        </p>
        <div className="flex mt-[30px]">
          <Link to="/Ourstory" className="green-btn">
            {" "}
            LEARN MORE
          </Link>
        </div>
      </div>

      <div className="who-we-are-right">
        <img src={team} alt="" className="who-we-are-img" />
      </div>
    </div>
  );
}

export default WhoWeAre;
