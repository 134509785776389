import React from "react";
import Contact from "../components/Contact/Contact";
import Footerr from "../components/Footerr/Footerr";
import Reception from "../components/Reception/Reception";
import { Header } from "../components/shared";

function ContactPage() {
  return (
    <div>
      <Header />
      <Contact />
      <Footerr />
    </div>
  );
}

export default ContactPage;
