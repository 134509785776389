import React, { useContext } from "react";
import FormDataContext from "./formContext";
import axios from "axios"; // Import the axios library for making HTTP requests

function SubmitPopup() {
  let { submitPopup, setSubmitPopup, setFinishPopup, data } =
    useContext(FormDataContext);

  const handleFormSubmit = async () => {
    try {
      // Prepare the data to be sent to the Google Apps Script
      const formData = new FormData();
      console.log(formData);
      for (const key in data) {
        formData.append(key, data[key]);
      }

      // Send data to Google Apps Script URL
      const scriptUrl =
        "https://script.google.com/macros/s/AKfycbzuYTsjzc4gbdqX7JL36Q4ppivpmcDvLJR2fwuOxdab0n6RVy6PxAEcrxHoBT6pwpiPjQ/exec";
      await axios.post(scriptUrl, formData);

      // After successful submission, you can update the UI state as needed
      setSubmitPopup(false);
      setFinishPopup(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {submitPopup && (
        <div className="popupContainer">
          <div className="popup">
            <span>
              Are you sure do you want to submit form? feel free to revise data
              first.
            </span>
            <div className="popupButtonsContainer">
              <button
                className="secondaryButton"
                onClick={() => {
                  setSubmitPopup(false);
                }}
              >
                Cancel
              </button>
              <button className="primaryButton" onClick={handleFormSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubmitPopup;
