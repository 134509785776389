import { FaLightbulb } from "react-icons/fa";
import { BsGlobe, BsTools } from "react-icons/bs";
import { RiStockFill, RiPriceTagFill } from "react-icons/ri";
import { GiLightBulb } from "react-icons/gi";
import { AiFillDollarCircle, AiOutlineDatabase } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import { MdSettingsInputSvideo, MdShoppingCart } from "react-icons/md";
import {
  IoIosPeople,
  IoMdArrowUp,
  IoIosCube,
  IoIosSettings,
  IoIosApps,
} from "react-icons/io";
import crm from "./Media/Services/crm.jpg";
import infra from "./Media/Services/infra.jpg";
import menu from "./Media/Services/menu.jpg";
import offers from "./Media/Services/offers.jpg";
import omarketing from "./Media/Services/omarketing.jpg";
import pos from "./Media/Services/pos.jpg";
import procurement from "./Media/Services/procurement.jpg";
import staff from "./Media/Services/staff.jpg";
import website from "./Media/Services/website.jpg";
import { Link } from "react-router-dom";

import sstaff from "./Media/Solutions/HomeSolutions/staff2.png";
import scounter from "./Media/Solutions/HomeSolutions/counter3.jpg";
import soffers from "./Media/Solutions/HomeSolutions/offers.png";
import swebsite from "./Media/Solutions/HomeSolutions/swebsite.png";
import sproduct from "./Media/Solutions/HomeSolutions/new product.png";
import sprocess from "./Media/Solutions/HomeSolutions/process.png";
import smarket from "./Media/Solutions/HomeSolutions/marketing.png";
import sitem from "./Media/Solutions/HomeSolutions/items.png";

import new_menu from "./Media/Latest/Restaurant NEW/MENU MANAGEMENT NEWW.png";
import new_crm from "./Media/Latest/Restaurant NEW/CUSTOMER RELATIONSHIP MANAGEMENT.png";
import new_infra from "./Media/Latest/Restaurant NEW//INFRASTRUCTURE HANDLING.png";
import new_marketing from "./Media/Latest/Restaurant NEW/ONLINE MARKETING.png";
import new_offers from "./Media/Latest/Restaurant NEW/OFFERS & PROMOTION PLANNING.png";
import new_pos from "./Media/Latest/Restaurant NEW/POS INTEGRATION.png";
import new_purchase from "./Media/Latest/Restaurant NEW/PURCHASE MANAGEMENT.png";
import new_staff from "./Media/Latest/Restaurant NEW/STAFF TRAINING.png";
import new_website from "./Media/Latest/Restaurant NEW/WEBSITE DESIGN.png";

import infra_new from "./Media/Latest/Supermarkets NEW/INFRASTRUCTURE HANDLING.png";
import staf_new from "./Media/Latest/Supermarkets NEW/STAFF TRAINING.png";
import offers_new from "./Media/Latest/Supermarkets NEW/OFFERS & PROMOTIONS MANAGEMENT.png";
import website_new from "./Media/Latest/Supermarkets NEW/WEBSITE DESIGN.png";
import product_new from "./Media/Latest/Supermarkets NEW/NEW PRODUCT INFUSION.png";
import process_new from "./Media/Latest/Supermarkets NEW/Build & Enhance processes.png";
import market_new from "./Media/Latest/Supermarkets NEW/Digital marketing.png";
import item_new from "./Media/Latest/Supermarkets NEW/Item Standardization.png";

import {
  MenuManagement,
  WebsiteDesign,
  OnlineMarketing,
  OffersPromotion,
  CRM,
  PurchaseManagement,
  InfraHandling,
  StaffTraining,
  POS,
} from "./ServicesContent/ServicesContent";
import {
  SInfraHandling,
  SItem,
  SMarketing,
  SNewProduct,
  SOffers,
  SProcesses,
  SStaffTraining,
  SWebsite,
} from "./SolutionsContent/SolutionsContent";

export const Services = [
  {
    id: 1,
    content: MenuManagement,
    image: menu,
    image_new: new_menu,
    icon: <BsGlobe />,
    item: "Menu Management",
    sd: "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers.",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    benefits: [
      "Dynamic menu updates",
      "Improved customer experience",
      "Increased revenue through menu optimization",
      "Consistent menu information",
      "Easy customization for special occasions",
    ],
    description2:
      "Stay competitive with a dynamic menu management system. Easily showcase daily specials, promotions, and dietary information, providing your customers with a delightful dining experience.",
  },
  {
    id: 2,
    content: WebsiteDesign,
    image: website,
    image_new: new_website,
    icon: <BsGlobe />,
    item: "Website Design",
    sd: "Create a professional and attractive website to showcase your restaurant's offerings and engage with online customers.",
    description:
      "Create a professional and attractive website to showcase your restaurant's offerings and engage with online customers. Stunning restaurant website design, enhanced online visibility, and improved customer engagement.",
    benefits: [
      "Stunning restaurant website design",
      "Enhanced online visibility",
      "Improved customer engagement",
      "Online reservations and ordering",
      "Responsive design for all devices",
    ],
    description2:
      "Boost your restaurant's online presence with a visually stunning website. Captivate potential customers, showcase your menu, and provide convenient online reservation and ordering options.",
  },
  {
    id: 3,
    content: OnlineMarketing,
    image: omarketing,
    image_new: new_marketing,
    icon: <BsGlobe />,
    item: "Online Marketing",
    sd: "Leverage online marketing strategies to expand your restaurant's online presence and attract a broader audience.",
    description:
      "Leverage online marketing strategies to expand your restaurant's online presence and attract a broader audience. Increased online visibility, targeted marketing campaigns, and greater audience engagement.",
    benefits: [
      "Increased online visibility",
      "Targeted marketing campaigns",
      "Greater audience engagement",
      "Data-driven marketing decisions",
      "Improved ROI on digital campaigns",
    ],
    description2:
      "Harness the power of digital marketing to reach a wider audience. Drive online visibility, engage with your community, and tailor marketing campaigns for maximum impact.",
  },
  {
    id: 4,
    content: OffersPromotion,
    image: offers,
    image_new: new_offers,
    icon: <BsGlobe />,
    item: "Offers & Promotion Planning",
    sd: "Plan and execute promotional campaigns and special offers to attract and retain restaurant customers.",
    description:
      "Plan and execute promotional campaigns and special offers to attract and retain restaurant customers. Increased customer engagement, boosted sales during promotions, and improved customer loyalty.",
    benefits: [
      "Increased customer engagement",
      "Boosted sales during promotions",
      "Improved customer loyalty",
      "Data-driven campaign decisions",
      "Competitive advantage",
    ],
    description2:
      "Boost your restaurant's sales and customer loyalty with strategically planned promotions. Drive customer engagement, increase sales during special offers, and gain a competitive edge.",
  },
  {
    id: 5,
    content: CRM,
    image: crm,
    image_new: new_crm,
    icon: <BsGlobe />,
    item: "Customer Relationship Management",
    sd: "Implement a customer relationship management system to enhance customer interactions and loyalty.",
    description:
      "Implement a customer relationship management system to enhance customer interactions and loyalty. Improved customer relationships, enhanced customer loyalty, and personalized customer interactions.",
    benefits: [
      "Improved customer relationships",
      "Enhanced customer loyalty",
      "Data-driven decisions",
      "Personalized customer interactions",
      "Customer feedback and analysis",
    ],
    description2:
      "Strengthen customer relationships with personalized interactions. Improve loyalty through data-driven decisions, customer feedback analysis, and tailored services.",
  },
  {
    id: 6,
    content: PurchaseManagement,
    image: procurement,
    image_new: new_purchase,
    icon: <BsGlobe />,
    item: "Purchase Management",
    sd: "Optimize your restaurant's procurement process to reduce costs, improve supplier relationships, and enhance efficiency.",
    description:
      "Optimize your restaurant's procurement process to reduce costs, improve supplier relationships, and enhance efficiency. Cost-effective procurement, supplier relationship improvement, and streamlined procurement workflow.",
    benefits: [
      "Cost-effective procurement",
      "Supplier relationship improvement",
      "Streamlined procurement workflow",
      "Inventory cost reduction",
      "Enhanced supply chain management",
    ],
    description2:
      "Efficiently manage your restaurant's supply chain. Reduce costs, enhance supplier relationships, and streamline procurement for optimized inventory and operational efficiency.",
  },
  {
    id: 7,
    content: InfraHandling,
    image: infra,
    image_new: new_infra,
    icon: <BsGlobe />,
    item: "Infrastructure Handling",
    sd: "Implement a robust infrastructure management plan to ensure the smooth operation of your restaurant and minimize downtime.",
    description:
      "Implement a robust infrastructure management plan to ensure the smooth operation of your restaurant and minimize downtime. Improved infrastructure reliability, reduced downtime, and cost-effective maintenance.",
    benefits: [
      "Improved infrastructure reliability",
      "Reduced downtime",
      "Cost-effective maintenance",
      "Enhanced operational efficiency",
      "Extended asset lifespan",
    ],
    description2:
      "Ensure your restaurant's seamless operation with efficient infrastructure handling. Minimize downtime, extend asset lifespan, and enhance operational efficiency with our comprehensive maintenance plan.",
  },
  {
    id: 8,
    content: StaffTraining,
    image: staff,
    image_new: new_staff,
    icon: <IoIosPeople />,
    item: "Staff Training",
    sd: "Invest in staff training and maintenance programs to enhance skills, productivity, and overall job satisfaction.",
    description:
      "Invest in staff training and maintenance programs to enhance skills, productivity, and overall job satisfaction. Skilled and motivated workforce, higher productivity, and reduced employee turnover.",
    benefits: [
      "Skilled and motivated workforce",
      "Higher productivity",
      "Reduced employee turnover",
      "Enhanced company culture",
      "Improved customer service",
    ],
    description2:
      "Nurture a skilled and motivated workforce through tailored staff maintenance programs. Boost productivity, reduce turnover, and foster a positive company culture.",
  },
  {
    id: 9,
    content: POS,
    image: pos,
    image_new: new_pos,
    icon: <BsGlobe />,
    item: "POS Integration",
    sd: "Integrate modern PoS systems to streamline restaurant operations and improve customer service.",
    description:
      "Integrate modern PoS systems to streamline restaurant operations and improve customer service. Efficient order processing, seamless payment transactions, improved order accuracy, enhanced customer experience.",
    benefits: [
      "Efficient order processing",
      "Seamless payment transactions",
      "Improved order accuracy",
      "Enhanced customer experience",
      "Real-time sales data analysis",
    ],
    description2:
      "Enhance restaurant operations with modern PoS integration. Streamline ordering, payment transactions, and elevate the overall customer experience. Real-time sales data analysis for data-driven insights.",
  },
];

export const SuperSolutions = [
  {
    id: 10,
    content: SInfraHandling,
    image: scounter,
    image_new: infra_new,
    icon: <BsGlobe />,
    item: "Infrastructure handling",
    sd: "We guide you in maintaining and taking care of your entire infrastructure for smooth flow of the processes",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 11,
    content: SStaffTraining,
    image: sstaff,
    image_new: staf_new,
    icon: <BsGlobe />,
    item: "Staff Training",
    sd1: "Integrate modern PoS systems to streamline restaurant operations and improve customer service.",
    sd: "Efficient staff means effective work flow, we help you in making your staff efficient no matter the workload.",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 12,
    content: SOffers,
    image: soffers,
    image_new: offers_new,
    icon: <BsGlobe />,
    item: "Offers & Promotions Management",
    sd1: "Integrate modern PoS systems to streamline restaurant operations and improve customer service.",
    sd: "A best offer acts like a customer magnet that attracts them towards buying, we will assist you to design best promotions. ",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 13,
    content: SWebsite,
    image: swebsite,
    image_new: website_new,
    icon: <BsGlobe />,
    item: "Website Design",
    sd1: "Integrate modern PoS systems to streamline restaurant operations and improve customer service.",
    sd: "A website is a market beyond your store, empowering your to earn effortlessly. We give you the best website.  ",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 14,
    content: SNewProduct,
    image: sproduct,
    image_new: product_new,
    icon: <BsGlobe />,
    item: "New Product Infusion",
    sd1: "Integrate modern PoS systems to streamline restaurant operations and improve customer service.",
    sd: "Trendy products must never be missed from your items list, We will make sure by deep analysis and modern techniques.",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 15,
    content: SProcesses,
    image: sprocess,
    image_new: process_new,
    icon: <BsGlobe />,
    item: "Build & Enhance Processes",
    sd1: "Integrate modern PoS systems to streamline restaurant operations and improve customer service.",
    sd: "A well defined process is like a foolproof machine that runs very smoothly. We have designed the process that succeeded the test of time.",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 16,
    content: SMarketing,
    image: smarket,
    image_new: market_new,
    icon: <BsGlobe />,
    item: "Digital Marketing",
    sd: "Digital Marketing is the key in improving your customer base in the 21st century. We elevate you in the digital market.",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
  {
    id: 17,
    image: sitem,
    image_new: item_new,
    content: SItem,
    icon: <BsGlobe />,
    item: "Item Standardization",
    sd: "A clumsy item master is the worst nightmare you can imagine in supermarket business. We are the experts in making it simpler and better.",
    description:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
    description2:
      "Efficiently manage and update your restaurant's menu, ensuring accuracy and appeal to your customers. Dynamic menu updates, improved customer experience, and increased revenue through menu optimization.",
  },
];

export const Stats = [
  // {
  //   id: 1,
  //   number: "Industry Expertise",
  //   explanation1: "Deep knowledge of your industry",
  //   explanation2: "Tailored solutions that work",
  // },
  {
    id: 2,
    number: "Data-Driven Insights",
    explanation1: "Real-time analytics",
    explanation2: "Maximizing profit through data",
  },
  {
    id: 3,
    number: "Customer-Centric",
    explanation1: "Dedicated support, your way",
    explanation2: "Growth built on your needs",
  },
  {
    id: 4,
    number: "Future-Proof Solutions",
    explanation1: "Cutting-edge tech in your hands",
    explanation2: "Adapt and conquer the future",
  },
  {
    id: 5,
    number: "Proven Results",
    explanation1: "Rollover Warangal",
    explanation2: "Akshaya Supermarket",
  },
];
