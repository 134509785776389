import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import Img1 from "./Media/bg.png";
import "./Reception.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaFacebookF, FaYoutube, FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const Reception = () => {
  const phrases = [
    "with NEW IDEAS",
    "with CORE VALUES",
    "with DEDICATION",
    "with HARD WORK",
    "with SMART WORK",
    "with CUSTOMER CENTRIC",
    "with EXCELLENCE",
    "brings you SUCCESS",
  ];
  const [isWayPoint, setIsWaypoint] = useState(false);
  // to handle waypoint
  const handleWayPoint = () => {
    setIsWaypoint(true);
  };
  var start = 0,
    end = 100,
    duree = 1,
    delta = Math.ceil((duree * 1000) / end);

  function count() {
    var node = document.getElementsByClassName("reception-number")[0];
    if (isWayPoint) {
      node.innerHTML = ++start;
      if (start < end) {
        // Si on est pas arrivé à la valeur finale, on relance notre compteur une nouvelle fois
        setTimeout(count, delta);
      }
    }
  }

  return (
    <div className="reception">
      <div className="overlay-image">
        <img src={Img1} alt="receptioniste" className="img-reception" />
        <div className="text-reception">
          <div className="reception-number">Best</div>
          <div className="reception-description">
            <span>Results </span>
            <span>Delivered</span>
            <Waypoint
              onEnter={() => {
                handleWayPoint();
              }}
            />
          </div>
        </div>
      </div>
      <div className="side-text">
        <span className="text-white text-[24px] font-['Open_Sans']">Aim</span>
        <h1 className="title1 title3 flex">
          {/* <span> &nbsp;</span> */}
          <span className="inline">
            {/* <TextScrambleComponent phrases={phrases} /> */}
            To Bring You Success
          </span>
        </h1>

        <div className=" paragraph-white pb-[15px]">
          Welcome to Terces's world of innovations. If you're dissatisfied with
          your company's profits or you have plans to grow it, move toward
          Terces because it's a great place to seek help from our team. Our team
          consists of marketing experts with brilliant business acumen. 
        </div>
        <span className="trait"></span>
        <div className="text-footer">
          <div className="side-mail">
            <a
              href="mailto: info@terces.io"
              className="paragraph-white"
              id="mail-story"
            >
              info@terces.io
            </a>
            <ReactTooltip
              anchorId="mail-story"
              place="top"
              content="Write to us"
              className="copy-box-home-vision2"
            />
          </div>

          <div className="flex gap-[10px]">
            <a
              href="https://www.facebook.com/Terces1555"
              className="bg-white p-[6px] rounded-[15px] t-footer-icon"
              id="facebook-div12"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="text-[#002549] fb" />
            </a>
            <ReactTooltip
              anchorId="facebook-div12"
              place="top"
              content="Like Us"
              className="copy-box-home-vision2"
            />
            <a
              href="https://www.youtube.com/@TercesSolutionsPrivatelimited/channels"
              className="bg-white p-[6px] rounded-[15px] t-footer-icon"
              id="youtube-div12"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-[#002549] yt" />
            </a>
            <ReactTooltip
              anchorId="youtube-div12"
              place="top"
              content="Subscribe Us"
              className="copy-box-home-vision2"
            />
            <a
              href="https://www.instagram.com/terces_solutions/"
              className="bg-white p-[6px] rounded-[15px]  t-footer-icon"
              id="insta-div12"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiInstagramFill className="text-[#002549] insta" />
            </a>
            <ReactTooltip
              anchorId="insta-div12"
              place="top"
              content="Like Us"
              className="copy-box-home-vision2"
            />
            <a
              href="https://twitter.com/terceS_sol"
              className="bg-white p-[6px] rounded-[15px] t-footer-icon"
              id="twitter-div12"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="text-[#002549] twitter" />
            </a>
            <ReactTooltip
              anchorId="twitter-div12"
              place="top"
              content="Follow Us"
              className="copy-box-home-vision2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reception;
