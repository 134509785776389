import insights from "./Media/insights.png";
import ccentric from "./Media/happyclient.png";
import fproof from "./Media/fproof.png";
import results from "./Media/results.png";

export const BlogData2 = [
  {
    id: 1,
    image: insights,
    name: "Data-Driven Insights",
    description:
      "Our selection of rings features expert craftsmanship. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget varius nunc. Integer faucibus nibh odio, ut mattis turpis blandit ullamcorper. ",
  },
  {
    id: 2,
    image: ccentric,
    name: "Customer-Centric",
    description:
      "Elevate any outfit with our stunning collection of earrings. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget varius nunc. Integer faucibus nibh odio, ut mattis turpis blandit ullamcorper. ",
  },
  {
    id: 3,
    image: fproof,
    name: "Best Solutions",
    description:
      "Make a statement with our exquisite pendants. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget varius nunc. Integer faucibus nibh odio, ut mattis turpis blandit ullamcorper. ",
  },
  {
    id: 4,
    image: results,
    name: "Proven Results",
    description:
      "Make a statement with our exquisite pendants. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget varius nunc. Integer faucibus nibh odio, ut mattis turpis blandit ullamcorper. ",
  },
];

export const BlogData = [
  {
    id: 1,
    image: insights,
    name: "Smart Solutions",
    description:
      "Uncover valuable data insights with our advanced analysis tools. Make informed decisions and optimize your strategy.",
  },
  {
    id: 2,
    image: ccentric,
    name: "Happy Clients",
    description:
      "Our customer-centric approach ensures that every product and service is tailored to meet your unique needs.",
  },
  {
    id: 3,
    image: fproof,
    name: "Creative Tech",
    description:
      "Stay ahead with our future-proof solutions. We adapt and evolve to keep your business on the cutting edge.",
  },
  {
    id: 4,
    image: results,
    name: "Proven Results",
    description:
      "Experience proven results with our data driven solutions. Join our satisfied customers and achieve success.",
  },
];
