import Modal from "react-modal";
import "./../ServicesContent/ServicesContent.css";

import { useMediaQuery } from "react-responsive";

import { Link } from "react-router-dom";
import offersquote from "./../Media/Quotes/quote-offers.jpg";
import crmquote from "./../Media/Quotes/quote-CRM.jpg";

import crm_base from "./../Media/crm/base.png";
import crm_engagement from "./../Media/crm/engagement.png";
import crm_retention from "./../Media/crm/retention.png";
import crm_updation from "./../Media/crm/updation.png";

import offers_new from "./../Media/offers/new.png";
import offers_loyal from "./../Media/offers/loyal.png";
import offers_festival from "./../Media/offers/festival.png";
import offers_occasion from "./../Media/offers/occassion.png";
import offers_best from "./../Media/offers/best_seller.png";
import offers_recommended from "./../Media/offers/recommended.png";

import infra_lights from "./../Media/Solutions/InfraHandling/lights.png";
import infra_racks from "./../Media/Solutions/InfraHandling/racks.png";
import infra_signs from "./../Media/Solutions/InfraHandling/signs.png";
import infra_layout from "./../Media/Solutions/InfraHandling/layout.png";

import staff_profile from "./../Media/Solutions/StaffTraining/profile.png";
import staff_schedule from "./../Media/Solutions/StaffTraining/schedule.png";
import staff_module from "./../Media/Solutions/StaffTraining/module.png";
import staff_checklist from "./../Media/Solutions/StaffTraining/checklist.png";

import quote_infra from "./../Media/Solutions/SQuotes/quoteinfra.jpg";
import quote_staff from "./../Media/Solutions/SQuotes/quotestaff.jpg";
import quote_offers from "./../Media/Solutions/SQuotes/quoteoffers.jpg";

import soffers_deals from "./../Media/Solutions/Offers/deals.png";
import soffers_occassion from "./../Media/Solutions/Offers/occassion.png";
import soffers_pricing from "./../Media/Solutions/Offers/pricing.png";
import soffers_product from "./../Media/Solutions/Offers/product.png";
import soffers_season from "./../Media/Solutions/Offers/season.png";
import soffers_special from "./../Media/Solutions/Offers/special.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import React from "react";

const sinfra = [
  {
    id: 1,
    bg: infra_lights,
    heading: "Brighter Stores",
    explanation:
      "Efficient lighting to improve the shopping environment and save on energy costs.",
  },
  {
    id: 2,
    bg: infra_racks,
    heading: "Stronger Shelves",
    explanation:
      "Sturdy racks for longer-lasting products and easier stocking.",
  },
  {
    id: 3,
    bg: infra_signs,
    heading: "Eye-Catching Signs",
    explanation: "Engaging digital signs that inform and boost sales.",
  },
  {
    id: 4,
    bg: infra_layout,
    heading: "Store Makeover",
    explanation:
      "Enhanced store layout and design for a better shopping experience and increased sales.",
  },
];

const sinfra_benefits = [
  {
    id: 1,
    heading: "Cost Savings",
    explanation:
      "Efficient lighting and rack maintenance reduce operational costs, improving your bottom line.",
  },
  {
    id: 2,
    heading: "Increased Sales",
    explanation:
      "Engaging digital signage and optimal store layouts attract and retain more customers, boosting sales.",
  },
  {
    id: 3,
    heading: "Enhanced Shopping Experience",
    explanation:
      "Well-maintained racks and appealing store design create a pleasant shopping environment for customers.",
  },
  {
    id: 4,
    heading: "Product Longevity",
    explanation:
      "Sturdy racks and proper lighting extend the shelf life of products, reducing waste and costs.",
  },
];

const sstaff = [
  {
    id: 1,
    bg: staff_profile,
    heading: "Profile Training",
    explanation:
      "Tailored training plans to help your staff excel, making supermarket operations efficient and smooth.",
  },
  {
    id: 2,
    bg: staff_schedule,
    heading: "Scheduling Mastery",
    explanation:
      "Organized, on-time training ensures every staff member receives the right knowledge when needed.",
  },
  {
    id: 3,
    bg: staff_module,
    heading: "Multilingual Modules",
    explanation:
      "Training content available in local languages, ensuring understanding and effective learning for diverse staff.",
  },
  {
    id: 4,
    bg: staff_checklist,
    heading: "Maintenance Checklists",
    explanation:
      "Essential task lists simplify store upkeep, promoting smoother and cleaner supermarket operations.",
  },
];

const sstaff_benefits = [
  {
    id: 1,
    heading: "Enhanced Efficiency",
    explanation:
      "Optimize operations with tailored training, streamlined schedules, and multilingual support, making every task more efficient.",
  },
  {
    id: 2,
    heading: "Improved Customer Service",
    explanation:
      "Well-trained staff provide better customer experiences, leading to increased loyalty and higher sales.",
  },
  {
    id: 3,
    heading: "Cost Savings",
    explanation:
      "Efficient maintenance and reduced errors translate to cost savings and improved profitability for your supermarket.",
  },
  {
    id: 4,
    heading: "Compliance Assurance",
    explanation:
      "Maintain industry standards and regulatory compliance through structured training and task checklists.",
  },
];

const soffers = [
  {
    id: 1,
    bg: soffers_product,
    heading: "Product Selection",
    explanation:
      "We help you choose the right products to maximize supermarket profits and meet customer demands effectively.",
  },
  {
    id: 2,
    bg: soffers_pricing,
    heading: "Easy Pricing",
    explanation:
      "We guide you to simplify price-setting to increase sales and revenue while keeping customers happy and loyal.",
  },
  {
    id: 3,
    bg: soffers_deals,
    heading: "Money-Making Deals",
    explanation:
      "We craft promotions that drive profits, ensuring your supermarket thrives with every sale and offer.",
  },
  {
    id: 4,
    bg: soffers_occassion,
    heading: "Special Occasions",
    explanation:
      "We assist you to harness the power of holidays and special events to boost sales and customer satisfaction.",
  },
  {
    id: 5,
    bg: soffers_special,
    heading: "Event Offers",
    explanation:
      "We guide you to tailor promotions for all occasions, making every shopping trip a rewarding experience for customers.",
  },
  {
    id: 6,
    bg: soffers_season,
    heading: "Seasonal Promotions",
    explanation:
      "We make you to adjust your marketing strategy to match the seasons, increasing sales and customer engagement effortlessly.",
  },
];

const soffers_benefits = [
  {
    id: 1,
    heading: "Increased Profits",
    explanation:
      "Optimize product selection and pricing to boost profits and maximize the supermarket's financial performance.",
  },
  {
    id: 2,
    heading: "Customer Satisfaction",
    explanation:
      "Tailored offers, promotions, and seasonal deals create happy, loyal customers, driving long-term success.",
  },
  {
    id: 3,
    heading: "Competitive Advantage",
    explanation:
      "Stay ahead of competitors with data-driven strategies that adapt to market changes and customer preferences.",
  },
  {
    id: 4,
    heading: "Efficient Operations",
    explanation:
      "Streamlined decision-making and automated processes lead to more efficient supermarket operations and reduced costs.",
  },
  {
    id: 5,
    heading: "Sales Growth",
    explanation:
      "Effective promotions and tailored marketing strategies result in increased sales and revenue for the supermarket.",
  },
];

const sproduct = [
  {
    id: 1,
    bg: soffers_product,
    heading: "Popular Picks",
    explanation:
      "Identify hot-selling items to maximize revenue, catering to customer preferences and market trends.",
  },
  {
    id: 2,
    bg: soffers_pricing,
    heading: "Category Insights",
    explanation:
      "Optimize product layout based on data-driven analysis, enhancing customer experience and boosting sales.",
  },
  {
    id: 3,
    bg: soffers_deals,
    heading: "Fill the Gaps",
    explanation:
      "Identify and stock missing categories to ensure a well-rounded inventory, meeting diverse customer needs.",
  },
  {
    id: 4,
    bg: soffers_occassion,
    heading: "Demand Detective",
    explanation:
      "Pinpoint high-demand products for timely stocking, ensuring products fly off the shelves, increasing profitability.",
  },
];

const sproduct_benefits = [
  {
    id: 1,
    heading: "Profit Surge",
    explanation:
      "Identify and prioritize high-demand products, boosting sales and maximizing revenue.",
  },
  {
    id: 2,
    heading: "Efficient Layout",
    explanation:
      "Optimize shelf arrangement based on data, enhancing customer experience and overall store efficiency.",
  },
  {
    id: 3,
    heading: "Diverse Inventory",
    explanation:
      "Fill missing categories to meet diverse customer needs, ensuring a well-rounded and attractive product selection.",
  },
  {
    id: 4,
    heading: "Timely Stocking",
    explanation:
      "Pinpoint on-demand products for timely stocking, reducing surplus and minimizing stockouts.",
  },
];

const sprocesses = [
  {
    id: 1,
    bg: soffers_product,
    heading: "Role Harmony",
    explanation:
      "Clearly define roles and responsibilities, promoting teamwork and enhancing overall operational efficiency.",
  },
  {
    id: 2,
    bg: soffers_pricing,
    heading: "Process Precision",
    explanation:
      "Build streamlined processes tailored to your supermarket, ensuring smooth operations and heightened productivity.",
  },
  {
    id: 3,
    bg: soffers_deals,
    heading: "Time Management",
    explanation:
      "Implement effective scheduling for tasks, optimizing time and resources for maximum productivity.",
  },
  {
    id: 4,
    bg: soffers_occassion,
    heading: "Alert Advantage",
    explanation:
      "Receive timely process alerts and reminders, minimizing oversights and maintaining a well-organized and proactive supermarket.",
  },
];

const sprocesses_benefits = [
  {
    id: 1,
    heading: "Team Harmony",
    explanation:
      "Clearly defined roles foster teamwork, enhancing communication and efficiency within supermarket staff.",
  },
  {
    id: 2,
    heading: "Operational Efficiency",
    explanation:
      "Streamlined processes tailored to your supermarket ensure optimal operations, reducing redundancies and improving productivity.",
  },
  {
    id: 3,
    heading: "Time Optimization",
    explanation:
      "Effective scheduling of tasks maximizes time and resource utilization, boosting overall productivity and profitability.",
  },
  {
    id: 4,
    heading: "Proactive Management",
    explanation:
      "Timely process alerts and reminders minimize oversights, promoting proactive and organized supermarket management.",
  },
];

const smarketing = [
  {
    id: 1,
    bg: soffers_product,
    heading: "Online Presence",
    explanation:
      "Establish a vibrant online presence with professionally created social media accounts to engage customers.",
  },
  {
    id: 2,
    bg: soffers_pricing,
    heading: "Engaging Content",
    explanation:
      "Elevate your brand with regular, engaging social media posts that captivate and inform your audience.",
  },
  {
    id: 3,
    bg: soffers_deals,
    heading: "Follower Boost",
    explanation:
      "Increase your follower base organically, expanding your reach and building a loyal online customer community.",
  },
  {
    id: 4,
    bg: soffers_occassion,
    heading: "Direct Connection",
    explanation:
      "Leverage Whatsapp for personalized marketing, connecting directly with customers for promotions and updates.",
  },
];

const smarketing_benefits = [
  {
    id: 1,
    heading: "Online Visibility",
    explanation:
      "Establish a strong online presence, attracting new customers and increasing visibility in the digital space.",
  },
  {
    id: 2,
    heading: "Engagement Boost",
    explanation:
      "Compelling content enhances customer engagement, fostering a loyal community around your supermarket brand.",
  },
  {
    id: 3,
    heading: "Audience Expansion",
    explanation:
      "Organically increase your follower base, expanding your reach and connecting with a broader audience online.",
  },
  {
    id: 4,
    heading: "Direct Marketing",
    explanation:
      "Utilize Whatsapp for personalized marketing, creating a direct and effective communication channel with your customers.",
  },
];

const swebsite = [
  {
    id: 1,
    bg: soffers_product,
    heading: "Digital Storefront",
    explanation:
      "Establish a powerful online presence with a modern website, showcasing your supermarket and products effectively.",
  },
  {
    id: 2,
    bg: soffers_pricing,
    heading: "Sleek Design",
    explanation:
      "Transform your supermarket's image with a modernistic website design that reflects the latest industry trends.",
  },
  {
    id: 3,
    bg: soffers_deals,
    heading: "Speed & Efficiency",
    explanation:
      "Ensure a high-performance website, providing customers with a seamless and efficient browsing and shopping experience.",
  },
  {
    id: 4,
    bg: soffers_occassion,
    heading: "Domain Security",
    explanation:
      "Trust us with your domain care, ensuring security and reliability for a worry-free online presence.",
  },
  {
    id: 5,
    bg: soffers_special,
    heading: "Continuous Assistance",
    explanation:
      "Benefit from ongoing support, guaranteeing that your website operates smoothly, and assistance is just a click away.",
  },
  {
    id: 6,
    bg: soffers_season,
    heading: "Market Expansion",
    explanation:
      "Boost your supermarket's reach with strategic online tools integrated into your website, expanding your market presence.",
  },
];
const swebsite_benefits = [
  {
    id: 1,
    heading: "Digital Showcase",
    explanation:
      "Create a powerful online presence, showcasing your supermarket and products to a global audience.",
  },
  {
    id: 2,
    heading: "Modern Image",
    explanation:
      "Transform your supermarket's image with a modernistic website design that aligns with current industry trends.",
  },
  {
    id: 3,
    heading: "Effortless Browsing",
    explanation:
      "Ensure a high-performance website, providing customers with a seamless and efficient browsing and shopping experience.",
  },
  {
    id: 4,
    heading: "Security Assurance",
    explanation:
      "Trust us with your domain care, ensuring the security and reliability of your supermarket's online presence.",
  },
  {
    id: 5,
    heading: "Continuous Support",
    explanation:
      "Benefit from ongoing support, guaranteeing that your website operates smoothly, and assistance is readily available.",
  },
  {
    id: 6,
    heading: "Market Expansion",
    explanation:
      "Amplify your supermarket's reach with strategic online tools integrated into your website, expanding your market presence.",
  },
];

const sitem = [
  {
    id: 1,
    bg: soffers_product,
    heading: "Data Assurance",
    explanation:
      "Ensure accuracy by validating and verifying existing item data, enhancing data reliability.",
  },
  {
    id: 2,
    bg: soffers_pricing,
    heading: "Data Purity",
    explanation:
      "Purge inaccuracies from existing item data, ensuring a clean and reliable foundation for operations.",
  },
  {
    id: 3,
    bg: soffers_deals,
    heading: "Category Harmony",
    explanation:
      "Define standard categories for items, simplifying organization and creating a consistent shopping experience.",
  },
  {
    id: 4,
    bg: soffers_occassion,
    heading: "Master Check",
    explanation:
      "Regularly audit the item master, ensuring ongoing accuracy, compliance, and efficiency in supermarket operations.",
  },
];

const sitem_benefits = [
  {
    id: 1,
    heading: "Data Accuracy",
    explanation:
      "Validate and verify existing item data, ensuring accuracy and reliability for streamlined supermarket operations.",
  },
  {
    id: 2,
    heading: "Clean Data Foundation",
    explanation:
      "Purge inaccuracies, providing a cleansed and reliable data foundation for smooth and efficient operations.",
  },
  {
    id: 3,
    heading: "Consistent Organization",
    explanation:
      "Define standard categories for items, simplifying organization and creating a consistent shopping experience for customers.",
  },
  {
    id: 4,
    heading: "Operational Efficiency",
    explanation:
      "Regularly audit the item master, ensuring ongoing accuracy, compliance, and operational efficiency in the supermarket.",
  },
];

export function SInfraHandling() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Taking care of infrastructure</h1>
      <p className="paragraph">
        From efficient lighting solutions that brighten your store and save on
        energy costs to robust shelving that keeps your products organized and
        fresh, we've got you covered. Engage your customers with captivating
        digital signage, and boost your sales.
      </p>
      <br />
      <h1 className="benefits-title">Streamline Your Store Success</h1>
      <br />
      <p className="paragraph">
        We even provide expert layout and design services to optimize your
        store's layout for an exceptional shopping experience.
      </p>
      <div className="website-benefits-div">
        {sinfra.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        With our supermarket solutions, you're not just maintaining
        infrastructure; you're revitalizing your store, attracting more
        customers, and increasing revenue. Let's simplify success and streamline
        your supermarket's journey to greater profitability.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Take care of my infrastructure"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {sinfra_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_infra} alt="" />
    </div>
  );
}

export function SStaffTraining() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Training made efficient</h1>
      <p className="paragraph">
        In the dynamic world of supermarkets, ensuring well-trained and skilled
        staff is crucial for success. Our "Supermarket Training Solutions" offer
        a comprehensive approach to staff development, featuring profile-based
        training, efficient scheduling, multilingual modules, and maintenance
        checklists.
      </p>
      <br />
      <h1 className="benefits-title">Empowering Efficient Store Operations</h1>
      <br />
      <p className="paragraph">
        With tailored training plans, on-time delivery of knowledge,
        multilingual support, and simplified maintenance routines, we empower
        your supermarket to run seamlessly. Let us be your partner in achieving
        operational excellence.
      </p>
      <div className="website-benefits-div">
        {sstaff.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        Elevate your supermarket with our holistic training solutions. From
        personalized staff development to streamlined maintenance procedures, we
        enhance efficiency and customer satisfaction. Join us in shaping a
        brighter future for your store.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        Train my Staff
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {sstaff_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_staff} alt="" />
    </div>
  );
}

export function SOffers() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Retail Success Solutions</h1>
      <p className="paragraph">
        In today's competitive retail landscape, supermarkets face the challenge
        of staying profitable while meeting customer demands. Our Retail Success
        Solutions offer a comprehensive suite of tools to help supermarket
        owners achieve their financial goals.
      </p>
      <br />
      <h1 className="benefits-title">Empowering Supermarkets for Growth</h1>
      <br />
      <p className="paragraph">
        From product selection to seasonal promotions, our software simplifies
        decision-making, enabling supermarket owners to thrive.
      </p>
      <div className="website-benefits-div">
        {soffers.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        In the dynamic world of retail, adapting to customer needs and market
        trends is vital for success. Our Retail Success Solutions give
        supermarket owners the edge they need, helping them stay profitable,
        competitive, and customer-focused in an ever-evolving industry. Don't
        just run a supermarket; run a successful one with our empowering
        solutions.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        Get Offers Guidance
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {soffers_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_offers} alt="" />
    </div>
  );
}

export function SNewProduct() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Data-Driven Retail</h1>
      <p className="paragraph">
        Unlock the power of data-driven solutions for your supermarket. Elevate
        profits, analyze customer trends, optimize inventory, and thrive in the
        competitive retail landscape with our tailored tech solutions.
      </p>
      <br />
      <h1 className="benefits-title">Elevate, Analyze, Optimize, Thrive</h1>
      <br />
      <p className="paragraph">
        In today's dynamic retail world, staying ahead is crucial. Our suite of
        services, from identifying popular products to filling inventory gaps,
        ensures a comprehensive approach. Embrace innovation, empower your
        supermarket, and let data be your guide to sustained success.
      </p>
      <div className="website-benefits-div">
        {sproduct.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        In conclusion, our data-driven retail solutions are the compass guiding
        your supermarket to success. Harness insights, meet customer demands,
        and watch your business thrive in the ever-evolving market landscape.
        Elevate, optimize, and secure your place at the forefront of retail
        excellence.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Introduce a new product"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {sproduct_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_offers} alt="" />
    </div>
  );
}

export function SProcesses() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Operational Excellence Hub</h1>
      <p className="paragraph">
        Welcome to a new era of supermarket management. Streamline operations,
        schedule tasks effectively, and succeed together with our tailored
        solutions designed to enhance every aspect of your business.
      </p>
      <br />
      <h1 className="benefits-title">Streamline, Schedule, Succeed Together</h1>
      <br />
      <p className="paragraph">
        Unlock the full potential of your supermarket with our comprehensive
        suite of tools. From defining clear roles to implementing precise
        processes, our solutions ensure a well-oiled machine, ready for success
        in today's dynamic retail landscape.
      </p>
      <div className="website-benefits-div">
        {sprocesses.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        Our Operational Excellence Hub is your key to streamlined success.
        Efficient teams, precise workflows, meticulous schedules, and timely
        alerts propel your supermarket towards unparalleled operational
        excellence. Elevate your business with us.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Build a process" : "Build a process for my supermarket"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {sprocesses_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_offers} alt="" />
    </div>
  );
}

export function SMarketing() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Digital Impact Hub</h1>
      <p className="paragraph">
        Transform your supermarket's online presence with our Digital Marketing
        solutions. Connect with customers, engage through compelling content,
        and watch your business flourish in the digital landscape.
      </p>
      <br />
      <h1 className="benefits-title">Connect, Engage, Flourish Online</h1>
      <br />
      <p className="paragraph">
        Navigate the digital realm effortlessly. From establishing a strong
        social media presence to increasing followers and utilizing Whatsapp,
        our solutions empower your supermarket's growth in the digital age.
      </p>
      <div className="website-benefits-div">
        {smarketing.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        our Digital Impact Hub is your gateway to a thriving online presence.
        Connect, engage, and flourish with our tailored solutions, ensuring your
        supermarket stays ahead in the dynamic digital marketplace.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Begin my online presence"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {smarketing_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_offers} alt="" />
    </div>
  );
}

export function SWebsite() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Digital Storefront Solutions</h1>
      <p className="paragraph">
        Step into the future with our Website Design solutions. Elevate your
        supermarket's digital presence, impress customers with modern design,
        and expand your market reach effortlessly.
      </p>
      <br />
      <h1 className="benefits-title">Elevate, Impress, Expand Online</h1>
      <br />
      <p className="paragraph">
        From a sleek, high-performance website to continuous support and
        market-boosting features, our comprehensive solutions ensure your
        supermarket thrives in the digital landscape.
      </p>
      <div className="website-benefits-div">
        {swebsite.map((webItem, index) => (
          <div
            key={webItem.id}
            className="website-benefit"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        Our Digital Storefront Solutions are the key to a successful online
        supermarket presence. Trust us for an elegant, high-performing website
        that continuously expands your market reach.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get a website" : "Get a website for my supermarket"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {swebsite_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_offers} alt="" />
    </div>
  );
}

export function SItem() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <div className="">
      <h1 className="service-title">Item Harmony Hub</h1>
      <p className="paragraph">
        Introducing our Item Standardization solutions. Validate and cleanse
        data, define standard categories, and conduct regular audits for a
        supermarket that thrives on accuracy, efficiency, and organization.
      </p>
      <br />
      <h1 className="benefits-title">Validate, Cleanse, Organize, Succeed</h1>
      <br />
      <p className="paragraph">
        Streamline your supermarket operations with precision. From data
        assurance to category harmony, our solutions ensure your inventory is a
        reliable foundation for success.
      </p>
      <div className="website-benefits-div">
        {sitem.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-service"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading website-heading">{webItem.heading}</h1>
            <p className="website-para mt-[10px] ">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <p className="paragraph">
        Our Item Harmony Hub is your key to operational excellence. Validate,
        cleanse, and organize with us for a supermarket that thrives on
        precision, accuracy, and success.
      </p>
      <br />
      <br />

      <Link to="/Contact" className="green-btn">
        {isMobile ? "Get In Touch" : "Standardize my item master"}
      </Link>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="benefits-title">You Get</h1>
      <div className="benefits-ul">
        {sitem_benefits.map((webItem, index) => (
          <div
            key={webItem.id}
            className="online-benefit-item"
            style={{ backgroundImage: `url(${webItem.bg})` }}>
            <h1 className="heading">{webItem.heading}</h1>
            <p className="mt-[5px] paragraph">{webItem.explanation}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <Link to="/Contact" className="green-btn">
        Get In Touch With Us
      </Link>
      <br />
      <br />
      <br />
      <img src={quote_offers} alt="" />
    </div>
  );
}
