import React from "react";
import Footerr from "../components/Footerr/Footerr";
import { HeroSection } from "../components/home";

import { Header } from "../components/shared";
import WhoWeAre from "../components/WhoWeAre/WhoWeAre";
import RServices from "../components/ServicesRestaurantHome/RServices";
import WhyTercesHome from "../components/WhyTercesHome/WhyTercesHome";
import NewCoreValues from "../components/CoreValues/NewCoreValues";

function Homer() {
  return (
    <div>
      {" "}
      <Header />
      <div>
        <HeroSection />
        <RServices />
        <NewCoreValues/>
        <WhoWeAre />
        <WhyTercesHome />
        <Footerr />
      </div>
    </div>
  );
}

export default Homer;
