import social1 from "./Media/Team/Akhila4.png";
import social2 from "./Media/Team/Ravali3.png";
import social3 from "./Media/Team/Pavani.png";
import social4 from "./Media/Team/bhavana9.png";
import social5 from "./Media/Team/Raja9.png";
import social6 from "./Media/Team/KK3.png";
import social7 from "./Media/Team/Sunil1.png";
import social8 from "./Media/Team/RaviTeja9.png";
import social9 from "./Media/Team/Laxman9.png";
import social10 from "./Media/Team/Mithil9.png";
import social11 from "./Media/Team/SampathNew.png";

export const TeamData = [
  {
    id: 1,
    image: social6,
    name: "Kranthi Mamidala",
    role: "Developer",
    description: "Coding the future lines of code.",
    link: "https://www.linkedin.com/in/kranthi-kumar-mamidala/",
    fblink: "https://www.facebook.com/profile.php?id=100001754565227",
  },
  {
    id: 2,
    image: social11,
    name: "Sampath Alakanti",
    role: "Developer",
    description: "Architecting the digital realm.",
    link: "",
    fblink: "",
  },
  {
    id: 3,
    image: social4,
    name: "Bhavana Lanke",
    role: "Marketing",
    description: "Crafting marketing magic.",
    link: "",
    fblink: "",
  },
  {
    id: 4,
    image: social1,
    name: "Akhila Vanga",
    role: "Marketing",
    description: "Mastermind in marketing.",
    link: "",
    fblink: "",
  },
  // {
  //   id: 5,
  //   image: social8,
  //   name: "Ravi Teja",
  //   role: "Designer",
  //   description: "Transforming visions.",
  // },
  {
    id: 6,
    image: social2,
    name: "Ravali Korivi",
    role: "Designer",
    description: "Breathing life into designs.",
    link: "",
    fblink: "",
  },
  {
    id: 7,
    image: social7,
    name: "Sunil Marepally",
    role: "Data Analyst",
    description: "Unlocking insights from datasets.",
    link: "",
    fblink: "",
  },
  {
    id: 8,
    image: social3,
    name: "Pavani Garnepally",
    role: "Data Analyst",
    description: "Data - deciphering knowledge.",
    link: "",
    fblink: "",
  },
  {
    id: 9,
    image: social5,
    name: "Raja Karumuri",
    role: "Client Location Handler",
    description: "Navigating client journeys.",
    link: "",
    fblink: "",
  },
  {
    id: 10,
    image: social10,
    name: "Mithil Avanigadda",
    role: "Client Location Handler",
    description: "Client advocate.",
    link: "",
    fblink: "",
  },
  {
    id: 10,
    image: social9,
    name: "Laxman Kasarla",
    role: "Client Location Handler",
    description: "Making clients feel at home.",
    link: "",
    fblink: "",
  },
];
