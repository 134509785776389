import React from "react";
import Factors from "../components/Factors/Factors";
import Footerr from "../components/Footerr/Footerr";
import HeroOther from "../components/HeroOther/HeroOther";
import { Header } from "../components/shared";
import Solutions from "../components/Solutions/Solutions";

const HeroOtherData = {
  id: 1,
  h1: "Elegant Solutions",
  s1: "Pioneers in Food & Retail businesses",
  c1: "Terces is a team of passionate professionals that provides quality services for the food and beverage industry.",
  c2: "Our expertise and experience allow us to develop innovative processes and technologies tailored to meet our clients' unique needs. Here is list of services offered by Terces.",
};

function SolutionsPage() {
  return (
    <div>
      <Header />
      <HeroOther item={HeroOtherData} />
      <Solutions />
      <Factors />
      <Footerr />
    </div>
  );
}

export default SolutionsPage;
