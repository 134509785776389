import React from "react";
import "./Factors.css";

const Factors = () => {
  return (
    <>
      <div className="container-fluid solutions-offers ">
        <h1 class="title1 text-left mt-0 md:mt-[20px] pl-5 font-[Oswald] !text-[#002549]">
          {" "}
          Terces Offers
        </h1>
        <div className="row ">
          <div className="col-10 col-md-3 mx-auto  hover_shadow solutions-hero-card">
            <span>
              <i className="fa-solid fa-truck-fast myIcon my-2"></i>
            </span>
            <h4 class="heading">Production Optimization</h4>
            <hr class="solutions-hero-card-hr" />
            <h5 class="paragraph">
              Customized process and technology solutions to optimize food and
              beverage production.
            </h5>
            <div class="hover_color_bubble"></div>
          </div>
          <div className="col-10 col-md-3 mx-auto  hover_shadow myIcon solutions-hero-card">
            <span>
              <i className="fa-solid fa-clock-rotate-left"></i>
            </span>
            <h4 class="heading">Product Development </h4>
            <hr class="solutions-hero-card-hr" />
            <h5 class="paragraph">
              Innovative solutions for product ideation, prototyping,
              manufacturing, and launch in the food industry.
            </h5>
            <div class="hover_color_bubble"></div>
          </div>{" "}
          <div className="col-10 col-md-3 mx-auto  hover_shadow solutions-hero-card">
            <span>
              <i className="fa-solid fa-shield myIcon my-2"></i>
            </span>
            <h4 class="heading">Customized Solutions</h4>
            <hr class="solutions-hero-card-hr" />
            <h5 class="paragraph">
              Tailored process and technology solutions to meet the unique needs
              of your business. Contact us today.
            </h5>
            <div class="hover_color_bubble"></div>
          </div>
          <div className="col-10 col-md-3 mx-auto  hover_shadow solutions-hero-card">
            <span>
              <i className="fa-solid fa-cash-register myIcon my-2 "></i>
            </span>
            <h4 class="heading">Enhanced Assurance</h4>
            <hr class="solutions-hero-card-hr" />
            <h5 className="text-capitalize paragraph">
              Enhance the quality and safety of your products with our testing,
              analysis, and equipment upgrades.
            </h5>
            <div class="hover_color_bubble"></div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Factors;
