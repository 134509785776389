import React from "react";
import "./Team.scss";
import styled from "styled-components";
import { Line, SubTitle, Title } from "../Service/Service";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

import img1 from "./../../SocialMedia/Media/person-white.png";
import img2 from "./Media/team-2.png";
import img3 from "./../../SocialMedia/Media/Team/Anvesh2.png";

const Team = () => {
  return (
    <Container id="team" className="team-part12">
      <TitleSection className="title-team">
        <SubTitle className="title-team">
          <Line className="title-team bg-white" /> Expert & Experienced
        </SubTitle>
        <Title className="title-team ">Expert Management</Title>
      </TitleSection>
      <Members>
        <Member>
          <div class="bug-cards">
            <div class="bug-card">
              <span class="close"></span>
              <span class="arrow"></span>
              <article>
                <h2>Anvesh Thammu</h2>
                <div class="bug-title">Manager</div>
                <div class="pic">
                  <img src={img1} />
                </div>

                <div class="desc">
                  Highly skilled and dedicated manager with 8 years of
                  experience in leading teams and achieving business objectives
                  through effective strategies.
                </div>
              </article>
              <div class="actions">
                <button class="bug-btn">
                  <span>like</span>
                  <FaFacebookF class="bug-icon" />
                </button>
                <button class="bug-btn">
                  <span>Reach</span>
                  <FaLinkedinIn class="bug-icon" />
                </button>
              </div>
            </div>
          </div>
        </Member>

        <Member>
          <div class="bug-cards">
            <div class="bug-card">
              <span class="close"></span>
              <span class="arrow"></span>
              <article>
                <h2>Manoj Thammu</h2>
                <div class="bug-title">Adviser</div>
                <div class="pic">
                  <img src={img1} />
                </div>

                <div class="desc">
                  Bringing 16 years of financial expertise, our US-based CFO is
                  a seasoned professional in the field.
                </div>
              </article>
              <div class="actions">
                <button class="bug-btn">
                  <span>like</span>
                  <FaFacebookF class="bug-icon" />
                </button>
                <button class="bug-btn">
                  <span>Reach</span>
                  <FaLinkedinIn class="bug-icon" />
                </button>
              </div>
            </div>
          </div>
        </Member>
        <Member>
          <div class="bug-cards">
            <div class="bug-card">
              <span class="close"></span>
              <span class="arrow"></span>
              <article>
                <h2>Sudeesh Pabbathi</h2>
                <div class="bug-title">Chief Executive Officer</div>
                <div class="pic">
                  <img src={img1} />
                  {/* "https://rafaelavlucas.github.io/assets/images/img-1.jpeg" */}
                </div>

                <div class="desc">
                  For over two decades, our experienced US-based CEO has been at
                  the forefront of technical and process solutions.
                </div>
              </article>
              <div class="actions">
                <button class="bug-btn">
                  <span>like</span>
                  <FaFacebookF class="bug-icon" />
                </button>
                <button class="bug-btn">
                  <span>Reach</span>
                  <FaLinkedinIn class="bug-icon" />
                </button>
              </div>
            </div>
          </div>
        </Member>
      </Members>
    </Container>
  );
};

export default Team;

const Container = styled.section`
  padding: 2rem 5rem 2rem 7rem;
  padding-top: 10rem;

  @media (min-width: 1910px) {
    padding: 2rem 17rem;
    padding-top: 10rem;
  }

  @media (max-width: 1200px) {
    padding: 6rem 5rem;
  }
  @media (max-width: 900px) {
    padding: 4rem 4rem;

    padding-top: 6rem;
    margin-top: -2rem;
  }
  @media (max-width: 800px) {
    padding: 4rem 4rem;

    padding-top: 6rem;
    margin-top: -2rem;
  }
  @media (max-width: 700px) {
    padding: 40px 30px;

    padding-top: 8rem;
    margin-top: -2rem;
  }
`;

const TitleSection = styled.div``;

const Members = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 8rem 0rem 2rem;

  @media (max-width: 1200px) {
    width: 80%;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 0rem 2rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 3rem 0rem 2rem;
  }
  @media (max-width: 800px) {
    padding: 3rem 0rem 2rem;
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 2rem 0rem 2rem;
  }
`;
const Member = styled.div`
  cursor: pointer;

  &:nth-child(2) {
    margin-top: -100px;

    @media (max-width: 1200px) {
      margin-top: 0px;
    }
  }
  &:nth-child(3) {
    margin-top: -200px;

    @media (max-width: 1200px) {
      margin-top: 0px;
    }
  }

  img {
    width: 100%;
    display: flex;
  }
`;
const MemberWraper = styled.div`
  position: relative;
  img {
    transition: all ease-in-out 0.3s;
  }
`;

const Info = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  color: #fff;
  background: var(--button-background);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Name = styled.h4`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
`;
const Post = styled.p`
  margin: 10px 0px 20px;
  font-size: 16px;
`;
const SocialLinks = styled.div`
  display: flex;
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: #fff;
    margin: 0 5px;
  }
`;
