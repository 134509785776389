import React, { useState } from "react";
import "./SocialMedia.css";

import img3 from "./Media/Team/Anvesh2.png";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import person from "./Media/person-white.png";

function SocialMedia({ team, data, desc, role }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="social-right">
      <h1 className="main-title text-center">{team}</h1>
      <p className="paragraph social-team-desc">{desc}</p>
      <ul className="social-items">
        {data.map((social) => (
          <li
            key={social.id}
            className="social-list"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div class="bug-cards">
              <div class="bug-card">
                <span class="close"></span>
                <span class="arrow"></span>
                <article>
                  <h2>{social.name}</h2>
                  <div class="bug-title">{social.role}</div>
                  <div class="pic">
                    <img src={person} />
                  </div>

                  <div class="desc">{social.description}</div>
                </article>
                <div class="actions">
                  {social.fblink ? (
                    <a href={social.fblink} target="_blank" class="bug-btn">
                      <span>like</span>
                      <FaFacebookF class="bug-icon" />
                    </a>
                  ) : (
                    <button class="bug-btn">
                      <span>Like</span>
                      <FaFacebookF class="bug-icon" />
                    </button>
                  )}

                  {social.link ? (
                    <a
                      href={social.link}
                      target={social.link ? "_blank" : null}
                      class="bug-btn"
                    >
                      <span>Reach</span>
                      <FaLinkedinIn class="bug-icon" />
                    </a>
                  ) : (
                    <button class="bug-btn">
                      <span>Reach</span>
                      <FaLinkedinIn class="bug-icon" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SocialMedia;
