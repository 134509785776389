import { Footer } from "flowbite-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Footerr from "../Footerr/Footerr";
import HeroOther from "../HeroOther/HeroOther";
import { Header } from "../shared";
import { BlogsData } from "./BlogsData";
import hand from "./media/right.gif";

import { FaHandPointRight } from "react-icons/fa";

import "./LatestBlog.css";

const HeroOtherData = {
  id: 1,
  h1: "Empowerment Through Synergy",
  s1: "The Food & Beverage Innovators",
  c1: " At Terces, we're committed to delivering exceptional results for our clients. We believe our success is driven by our core values, culture of excellence, and expertise in process and technology enhancements",
  c2: "We empower our clients to achieve their growth objectives by optimizing their operations, streamlining their processes, and delivering personalized experiences that drive customer loyalty and revenue growth. Our values-driven approach ensures we always put our clients first and strive for excellence in everything we do.",
};

function SingleBlog() {
  const { productId } = useParams();
  const thisProduct = BlogsData.find((prod) => prod.ID === parseInt(productId));
  if (!thisProduct) {
    console.log(`Product with ID ${productId} not found.`);
  } else {
    console.log(thisProduct.Name);
  }

  const { currentBlogId } = thisProduct.ID; // Filter the blog data to exclude the current blog
  const remainingBlogs = BlogsData.filter((blog) => blog.ID !== currentBlogId);

  return (
    <div>
      <Header />
      <HeroOther item={HeroOtherData} />
      <div className="single-blog-part">
        <div class="Single-blog-section">
          <div class="flex justify-between">
            <div class=" title-blog">{thisProduct.title}</div>
            <div></div>
          </div>
          <div>
            <img src={thisProduct.image} class="Single-blog-image" />
            <br />
            <p>
              {thisProduct.body1 && (
                <p className="paragraph-blog">{thisProduct.body1}</p>
              )}{" "}
            </p>
            <h1 className="heading-blog">
              {thisProduct.bodyhead1 && (
                <h1 className="heading-blog">{thisProduct.bodyhead1}</h1>
              )}
            </h1>
            <p className="paragraph-blog">
              {thisProduct.body2 && (
                <p className="paragraph-blog">{thisProduct.body2}</p>
              )}
              <h1 className="heading-blog">
                {thisProduct.bodyhead2 && (
                  <h1 className="heading-blog">{thisProduct.bodyhead2}</h1>
                )}
              </h1>
              {thisProduct.body3 && (
                <p className="paragraph-blog">{thisProduct.body3}</p>
              )}{" "}
              {thisProduct.bodyhead3 && (
                <h1 className="heading-blog">{thisProduct.bodyhead3}</h1>
              )}
            </p>
            <p className="paragraph-blog">
              {thisProduct.body4 && (
                <p className="paragraph-blog">{thisProduct.body4}</p>
              )}{" "}
            </p>
            <p>
              {thisProduct.bodyhead4 && (
                <h1 className="heading-blog">{thisProduct.bodyhead4}</h1>
              )}
              {thisProduct.body5 && (
                <p className="paragraph-blog">{thisProduct.body5}</p>
              )}{" "}
              {thisProduct.bodyhead5 && (
                <h1 className="heading-blog">{thisProduct.bodyhead5}</h1>
              )}
              {thisProduct.body6 && (
                <p className="paragraph-blog">{thisProduct.body6}</p>
              )}{" "}
              {thisProduct.bodyhead6 && (
                <h1 className="heading-blog">{thisProduct.bodyhead6}</h1>
              )}
              {thisProduct.body7 && (
                <p className="paragraph-blog">{thisProduct.body7}</p>
              )}{" "}
              {thisProduct.bodyhead7 && (
                <h1 className="heading-blog">{thisProduct.bodyhead7}</h1>
              )}
              {thisProduct.body8 && (
                <p className="paragraph-blog">{thisProduct.body8}</p>
              )}{" "}
              {thisProduct.bodyhead8 && (
                <h1 className="heading-blog">{thisProduct.bodyhead8}</h1>
              )}
            </p>
            {thisProduct.body9 && (
              <p className="paragraph-blog">{thisProduct.body9}</p>
            )}
            {thisProduct.bodyhead9 && (
              <h1 className="heading-blog">{thisProduct.bodyhead9}</h1>
            )}
            {thisProduct.body10 && (
              <p className="paragraph-blog">{thisProduct.body10}</p>
            )}{" "}
            {thisProduct.bodyhead10 && (
              <h1 className="heading-blog">{thisProduct.bodyhead10}</h1>
            )}
            {thisProduct.body11 && (
              <p className="paragraph-blog">{thisProduct.body11}</p>
            )}
            {thisProduct.bodyhead11 && (
              <h1 className="heading-blog">{thisProduct.bodyhead11}</h1>
            )}
            {thisProduct.body12 && (
              <p className="paragraph-blog">{thisProduct.body12}</p>
            )}
            {thisProduct.bodyhead12 && (
              <h1 className="heading-blog">{thisProduct.bodyhead12}</h1>
            )}
          </div>
        </div>
        <div className="blog-links">
          <h1 className="heading-blog mb-[20px]">
            <span className="inline-block">
              {/* <FaHandPointRight /> */}
              <img src={hand} alt="" className="blog-hand" />
            </span>
            Other Blogs:
          </h1>
          <ul>
            {remainingBlogs.map((blog) => {
              if (blog.ID !== parseInt(productId)) {
                return (
                  <li key={blog.ID} className="blog-link">
                    <Link to={`/blogs/${blog.ID}`} className="blog-link">
                      {blog.title}
                    </Link>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
      <Footerr />
    </div>
  );
}

export default SingleBlog;
