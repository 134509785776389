import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bgimg from "./media/Flip.jpg";
import sideimg from "./media/2.png";
import img from "./media/TERCES Website Design.png";

import "./HeroSection.css";

const HeroSection = () => {
  return (
    <Container id="home">
      <Wraper>
        <LeftContent>
          <Title>Grow With Terces</Title>
          <Description>
            Happier customers, simpler operations!
            <br /> The modern technology solutions for restaurants and
            supermarkets!
          </Description>
          <Buttons>
            <Link to="/Services" className="btn-pulse pulse  green-btn">
              Food Business
            </Link>
            <Link to="/Solutions" className="btn-pulse pulse  green-btn">
              Retail Business
            </Link>
          </Buttons>
        </LeftContent>
        <RightContent>
          <img src={img} alt="" />
        </RightContent>
      </Wraper>
    </Container>
  );
};

export default HeroSection;

const Container = styled.section`
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  background: var(--primary-bg);
`;
const Wraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem 0rem 0rem;
  height: 100vh;
  @media (min-width: 1910px) {
    padding: 8rem 0rem 0px 15rem;
  }

  @media (max-width: 1200px) {
    padding: 0rem 1rem 0rem 5rem;
    padding-top: 88px;
    margin-top: -88px;
  }
  @media (max-width: 900px) {
    padding: 0rem 1rem 0rem 4rem;
    flex-direction: column;

    padding-top: 88px;
    margin-top: -88px;
  }
  @media (max-width: 800px) {
    padding: 0rem 1rem 0rem 4rem;
    flex-direction: column;

    padding-top: 88px;
    margin-top: -88px;
  }
  @media (max-width: 700px) {
    padding: 0rem 1rem 0rem 1rem;
    margin-top: 0px;
    height: 100vh;
    justify-content: center;
  }
`;
const LeftContent = styled.div`
  width: 50%;
  padding: 5rem 0rem 0rem 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1910px) {
    padding: 22vh 0rem 30vh;
  }

  @media (max-width: 700px) {
    width: 100%;
    padding: 40px 20px;
  }
`;
const Title = styled.h1`
    color: var(--text-white);
    font-size: 65px;
    font-weight: 800;
    line-height: 150%;
    font-family: Open Sans,sans-serif;    
}

  @media (max-width: 1200px) {
    font-size: 60px;
  }

  @media (max-width: 700px) {
    font-size: 36px;
    line-height: 52px;
    padding: 1rem 0rem;
  }
`;
export const Description = styled.p`
  color: var(--text-white);
  width: 100%;
  margin: 10px 0px;
  font-family: "Open sans", Sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: 1200px) {
    margin: 15px 0px;
    font-size: 18px;
    line-height: 28px;
  }
`;
const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 15px;
  font-family: "Open sans", Sans-serif;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;

const RightContent = styled.div`
  display: flex;
  justify-content: end;
  width: 50%;
  margin-top: auto;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 30px;
  }
  @media (max-width: 700px) {
    width: 100%;
    margin-top: -25px;
    display: none;
  }

  img {
    width: 100%;
    transform: scale(1.25) translateX(-10%) translateY(-10%);

    @media (max-width: 900px) {
      width: 100%;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;
