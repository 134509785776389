import React from "react";
import img1 from "./media/careercard1.png";
import { HiTrendingUp } from "react-icons/hi";
import { MdEmojiPeople } from "react-icons/md";
import { SlArrowRight } from "react-icons/sl";
import "./CareerCard.css";

import { Link } from "react-router-dom";

const CareerCard = () => {
  return (
    <div className="career-card-12">
      <section class="text-gray-600 body-font">
        <div class="career-card1 flex flex-wrap">
          <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden flex flex-col justify-start sticky-img">
            <img src={img1} alt="" className="w-[800px] career-img2" />
          </div>
          <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center relative">
            <div class="flex flex-col mb-10 lg:items-start items-center justify-center career-2">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full border-solid border-[#002549] border-2 mb-3">
                <HiTrendingUp fontSize={"40px"} color="#002549" />
              </div>
              <div class="flex-grow lg:!text-left text-center">
                <h2 class="text-gray-900 title-font font-medium mb-3 font-['Open_Sans'] text-[22px] !font-bold">
                  Join a Fast-Paced Team
                </h2>
                <p class="leading-relaxed text-[15px] font-['Open_Sans']">
                  Join our team at Terces and work in a fast-paced environment
                  where you can learn and grow. We're committed to investing in
                  our employees and providing opportunities for career
                  advancement.
                </p>

                <Link to="/Terces_Recruitment" className="flex mt-[20px]">
                  <SlArrowRight className="bg-[#264653] p-[8px] text-[42px] text-white" />
                  <button className="btn-side btn-11 hover-filled-slide-right">
                    <span>Join Us</span>
                  </button>
                </Link>
              </div>
            </div>

            <div class="flex flex-col mb-10 lg:items-start items-center mt-3 career-2">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full border-solid border-[#002549] border-2 mb-3">
                <MdEmojiPeople fontSize={"35px"} color="#264653" />
              </div>
              <div class="flex-grow lg:!text-left text-center">
                <h2 class="text-gray-900  title-font font-medium mb-3 font-['Open_Sans'] text-[22px] !font-bold">
                  Make a Difference
                </h2>
                <p class="leading-relaxed text-[15px] font-['Open_Sans']">
                  At Terces, we believe in making a positive impact on our
                  clients, our community, and the world. Join us and be a part
                  of a team that's dedicated to making a difference through our
                  work.
                </p>
                <Link to="/Terces_Recruitment" className="flex mt-[20px]">
                  <SlArrowRight className="bg-[#264653] p-[8px] text-[42px] text-white" />
                  <button className="btn-side btn-11 hover-filled-slide-right">
                    <span>Join Us</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CareerCard;
