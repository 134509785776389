import React from "react";
import styled from "styled-components";
import img1 from "./bg2.img.webp";
import "./History.css";
import Solutions2 from "../../Solutions/Solutions2";

const History = ({ data, head }) => {
  return (
    <div>
      {data.map((item) => {
        return (
          <Containere style={{ backgroundImage: `url(${img1})` }}>
            <Container className="services-hero-part">
              <LeftContent>
                <Title>{item.h1}</Title>
                <SubWraper>
                  <Line />
                  <Subtitle>{item.s1}</Subtitle>
                </SubWraper>
              </LeftContent>
              <RightContent>
                <HistoryDescription>
                  {item.c1}
                  <br /> <br />
                  {item.c2}
                </HistoryDescription>
              </RightContent>
            </Container>
            {/* <BluePrint head={head} /> */}
            <Solutions2 />
          </Containere>
        );
      })}
    </div>
  );
};

export default History;
const Containere = styled.section`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
`;
const Container = styled.section`
  display: flex;
  align-items: start;
  padding: 6rem 7rem 6rem 7rem;

  @media (max-width: 1200px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 900px) {
    padding: 1rem 6rem;
    flex-direction: column;
    margin: 20px 0rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 6rem;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    padding: 1rem 30px;
    padding-top: 80px;
  }
`;

const LeftContent = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 42px;
  color: var(--text-white);
  font-weight: 600;
  line-height: 60px;
  font-family: "Open Sans";

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media (max-width: 700px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: var(--font-description);
  width: 100%;
  margin: 30px 0px;

  @media (max-width: 1200px) {
    margin: 15px 0px;
  }
`;
const SubWraper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;
const Subtitle = styled.p`
  font-family: "Open Sans";
  font-size: 20px;
  color: #ffffffb0;
`;
const HistoryDescription = styled(Description)`
  margin: 0;
  font-family: "Open Sans";
  color: #ffffffb0;
  font-size: 18px;
`;
const Line = styled.p`
  width: 65px;
  height: 2px;
  background: white;
  margin-right: 30px;
`;
const RightContent = styled.div`
  width: 60%;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
  }
`;
